import { createStylesheet } from "../utils/styles";
import { useRef } from "react";
import { UITypography } from ".";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { CSSInterpolation } from "tss-react";
import {
  type RichTextEditorRef,
  RichTextEditor,
  MenuButtonUndo,
  MenuButtonRedo,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  MenuButtonIndent,
  MenuButtonUnindent,
  MenuButtonBlockquote,
  MenuButtonAddTable,
} from "mui-tiptap";

interface Props {
  onChange: (value?: string) => void;
  placeholder?: string;
  rows?: number;
  value?: string;
  helperText?: string;
  error?: boolean;
}

function UIRichTextEditor({ onChange, placeholder, rows = 3, value, helperText, error }: Props) {
  const classes = useStyles({ rows });
  const ref = useRef<RichTextEditorRef>(null);
  return (
    <div className={`${error ? classes.error : ""}`}>
      <RichTextEditor
        ref={ref}
        extensions={[
          StarterKit,
          Placeholder.configure({ placeholder }),
          Table.configure({ resizable: true }),
          TableRow,
          TableHeader,
          TableCell,
        ]}
        enableContentCheck
        content={value}
        onUpdate={({ editor }) => onChange(editor?.getHTML())}
        className={classes.editorCtr}
        RichTextFieldProps={{ RichTextContentProps: { className: classes.content } }}
        renderControls={(editor) => (
          <MenuControlsContainer className={classes.controls}>
            <MenuSelectHeading
              className={classes.headings}
              labels={{
                paragraph: <UITypography variant="body1">Body</UITypography>,
                heading1: <UITypography variant="h1">H1</UITypography>,
                heading2: <UITypography variant="h2">H2</UITypography>,
                heading3: <UITypography variant="h3">H3</UITypography>,
                heading4: <UITypography variant="h4">H4</UITypography>,
                heading5: <UITypography variant="h5">H5</UITypography>,
                heading6: <UITypography variant="h6">H6</UITypography>,
                empty: "Style...",
              }}
            />
            <MenuDivider />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonUndo />
            <MenuButtonRedo />
            <MenuDivider />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
            <MenuDivider />
            <MenuButtonIndent />
            <MenuButtonUnindent />
            <MenuDivider />
            <MenuButtonBlockquote />
            <MenuDivider />
            <MenuButtonAddTable />
          </MenuControlsContainer>
        )}
      />
      {Boolean(helperText) && (
        <UITypography color={error ? "error" : "inherit"} className={classes.helperText}>
          {helperText}
        </UITypography>
      )}
    </div>
  );
}

const useStyles = createStylesheet((theme, props) => ({
  editorCtr: {},
  content: {
    "& div:first-of-type": {
      minHeight: props.rows * 20,
      "& h1": theme.typography.h1 as CSSInterpolation,
      "& h2": theme.typography.h2 as CSSInterpolation,
      "& h3": theme.typography.h3 as CSSInterpolation,
      "& h4": theme.typography.h4 as CSSInterpolation,
      "& h5": theme.typography.h5 as CSSInterpolation,
      "& h6": theme.typography.h6 as CSSInterpolation,
      "& p": theme.typography.body1 as CSSInterpolation,
    },
  },
  controls: {},
  headings: {
    "& :first-of-type": theme.typography.subtitle1 as CSSInterpolation,
  },
  error: {
    "& > div:first-of-type > div:last-of-type": {
      border: `1px solid ${theme.palette.error.main}`,
    },
    "& [data-placeholder]:before": {
      color: theme.palette.error.main + " !important",
    },
  },
  helperText: {
    fontSize: "0.75rem",
    marginLeft: 14,
    marginRight: 14,
    marginTop: 4,
  },
}));

export default UIRichTextEditor;
