import { type User as OIDCUser } from "oidc-client-ts";
import { Schemas } from "../_app/api/types";

export type IdentityUser = OIDCUser;

export interface DecodedJWT {
  sub: string;
  client_id: string;
  tenant_ref: string;
  given_name?: string;
  family_name?: string;
  auth_time?: number;
  exp?: number;
  iss?: string;
  brand_ref?: string;
  scope?: string[];
}

export type AuthConfig = Schemas["CustomerPortalAuthConfigDto"];

export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export type VerifyTotpResponse = typeof SUCCESS | typeof ERROR;

export interface SignedInUser {
  id_token?: string;
  expired?: boolean;
  session_state?: string | null;
  access_token?: string;
  token_type?: string;
  scope?: string;
  profile: UserProfile;
  expires_at?: number;
  state?: unknown;
}

export interface UserProfile {
  sub?: string;

  [key: string]: any;
}

export interface PasswordPolicy {
  minimumLength: number;
  requireLowercase: boolean;
  requireNumbers: boolean;
  requireSymbols: boolean;
  requireUppercase: boolean;
  temporaryPasswordValidityDays: number;
}

export type ChangePasswordForm = {
  password: string;
  confirmPassword: string;
};
