import { Fragment } from "react/jsx-runtime";
import { PasswordPolicy, SignedInUser } from "./types";
import { z } from "zod";
import { createElement } from "react";

const COGNITO_TOKEN_ISSUER_URL = "https://cognito-idp.eu-west-2.amazonaws.com";

// Make sure to call this function after authConfig is set when using default value
export const isCognitoUser = (issuer?: string) => {
  if (issuer) {
    return issuer.startsWith(COGNITO_TOKEN_ISSUER_URL);
  } else {
    return window.authConfig?.tokenIssuerUrl.startsWith(COGNITO_TOKEN_ISSUER_URL);
  }
};

export const isImpersonatedUser = (user?: SignedInUser): boolean => {
  if (!user) return false;
  if (!isCognitoUser()) return user.profile.act;

  const keyPrefix = `CognitoIdentityServiceProvider.${window.authConfig?.customerPortalClientId}`;
  return localStorage.getItem(`${keyPrefix}.LastAuthUser`) === "impersonated_user";
};

export const createPasswordSchema = (passwordPolicy: PasswordPolicy | undefined) => {
  if (passwordPolicy === undefined) {
    return z.object({
      password: z.string(),
      confirmPassword: z.string(),
    });
  }

  let schema = z
    .string()
    .min(passwordPolicy.minimumLength, `Password must be at least ${passwordPolicy.minimumLength} characters long`);

  if (passwordPolicy.requireLowercase) {
    schema = schema.regex(/[a-z]/, "Password must contain at least one lowercase letter");
  }
  if (passwordPolicy.requireUppercase) {
    schema = schema.regex(/[A-Z]/, "Password must contain at least one uppercase letter");
  }
  if (passwordPolicy.requireNumbers) {
    schema = schema.regex(/[0-9]/, "Password must contain at least one number");
  }
  if (passwordPolicy.requireSymbols) {
    schema = schema.regex(
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
      `Password must contain at least one special character:\n
     ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + =`,
    );
  }

  return z
    .object({
      password: schema,
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords must match",
      path: ["confirmPassword"],
    });
};

export const formatMultilineErrorMessage = (message?: string) => {
  if (!message) return null;

  const lines = message.split("\n");

  if (lines.length < 2) return message;

  return createElement(
    Fragment,
    {},
    lines.map((line, i) =>
      createElement(
        Fragment,
        { key: `line-${i}` },
        createElement("span", {}, line),
        i < lines.length - 1 ? createElement("br") : null,
      ),
    ),
  );
};
