import api from "../_app/api";

export interface GetPermissionsParams {
  userId?: string;
  roleId?: string;
  levelId?: string;
}

export interface Permission {
  readonly id?: string;
  name: string;
  code: string;
  value: boolean | string;
  disabled?: boolean;
  disabledReason: DisabledReason | null;
  groupName: string | null;
  permissions?: Permission[] | null;
}

export enum DisabledReason {
  ByRole = "BY_ROLE",
  ByLevel = "BY_LEVEL",
}

export const getPermissions = async (params: GetPermissionsParams) => {
  const { data }: { data: Permission[] } = await api({
    method: "GET",
    url: "/permissions",
    context_injection: true,
    params,
  });
  return data?.map(parsePermissions);
};

export const getMyPermissions = async () => {
  const { data }: { data: Permission[] } = await api({
    method: "GET",
    url: "/permissions/my",
    context_injection: true,
  });
  return data?.map(parsePermissions);
};

export const getMyPermissionForCostCentreUser = async (costCentreId: string | undefined) => {
  const { data }: { data: Permission[] } = await api({
    method: "GET",
    url: `/permissions/my/cost-centres/${costCentreId}`,
  });

  return data?.map(parsePermissions);
};

export const parsePermissions = (p: Permission) => {
  // TODO: Temporary workaround for backwards compatibility, remove once ST-20577 is done
  p.value = parseBoolean(p.value);
  if (p?.permissions?.length) p.permissions = p?.permissions?.map(parsePermissions);
  return p;
};

export const parseBoolean = (value: string | boolean) => {
  if (typeof value === "boolean") {
    return value;
  }

  return value.toLowerCase().trim() === "true";
};
