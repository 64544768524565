import { useEffect, useState } from "react";
import { confirmSignIn, signIn } from "aws-amplify/auth";
import CognitoHeader from "../components/cognito/CognitoHeader";
import CognitoFooter from "../components/cognito/CognitoFooter";
import { useNavigate } from "react-router-dom";
import UILoader from "../../_app/components/UILoader";
import { ChangePasswordForm } from "../types";
import { UIAlert, UIButton, UIGrid, UIStack } from "../../_app/components";
import { useCognitoUserpoolPolicy, useLogout } from "../hooks";
import { useQueryParam } from "../../_app/hooks";
import { useQueryClient } from "react-query";
import CognitoPasswordChangeForm from "../components/cognito/CognitoPasswordChangeForm";

const ForcePasswordChange = () => {
  const [isSigning, setIsSigning] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { data: passwordPolicy, isFetching } = useCognitoUserpoolPolicy();
  const navigate = useNavigate();
  const username = useQueryParam("username") as string;
  const password = useQueryParam("pass") as string;
  const logout = useLogout();
  const queryClient = useQueryClient();

  // Sign in user programmatically using the encoded credentials
  useEffect(() => {
    const performSignIn = async () => {
      if (!username || !password) {
        setError("Oops! Invalid link. Try requesting a new one, or reach out to support for help.");
        setIsSigning(false);
        return;
      }
      // Sign out any existing user
      logout();

      signIn({ username, password: password })
        .catch(() => {
          setError(
            `Oops! This link isn't working. It may have expired or already been used. Please request a new one or contact support for assistance. The link remains valid for ${passwordPolicy?.temporaryPasswordValidityDays || 7} days.`,
          );
        })
        .finally(() => {
          setIsSigning(false);
        });
    };

    performSignIn();
  }, [username, password, passwordPolicy?.temporaryPasswordValidityDays]);

  async function handleChangePassword(form: ChangePasswordForm) {
    try {
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: form.password,
      });

      if (isSignedIn) {
        queryClient.clear();
        navigate("/");
      }
    } catch (error) {
      setError("Password change was not successful. Please try again or contact support if the issue persists.");
    }
  }

  if (isSigning || isFetching) return <UILoader />;

  if (error) {
    return (
      <UIStack
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UIAlert severity={"error"}>{error} </UIAlert>
        <UIButton onClick={() => navigate("/login")}>Go to login page</UIButton>
      </UIStack>
    );
  }

  return (
    <UIGrid width="480px">
      <CognitoHeader />
      <CognitoPasswordChangeForm heading="Change Password" passwordPolicy={passwordPolicy} onSubmit={handleChangePassword} />
      <CognitoFooter />
    </UIGrid>
  );
};

export default ForcePasswordChange;
