import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { UIAlert, UIButton, UIGrid, UILoader, UIStack } from "../../_app/components";
import { rowParser } from "../../_app/components/Table/helpers";
import FiltersCard from "../../filter/components/FiltersCard";
import { useFilterQueries } from "../../filter/hooks";
import { useAssets, useAssetsFilters, useExportAssets } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { filtersIdMap } from "../../filter/utils";
import { formatCostCentreCode } from "../../account/utils";
import { Asset } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { Download as DownloadIcon } from "../../_app/components/icons";
import UIDataTable, { UIDataTableState } from "../../_app/components/Table/UIDataTable";
import { Feature } from "../../feature/types";

const ASSETS_FILTERS: string = "ASSETS";

export const SearchAssets = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [canView, setCanView] = useState(false);
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const queries = useFilterQueries();
  const liveOnly = JSON.parse(queries.find((q) => q.id === filtersIdMap.STATUS)?.value ?? "false");

  const { data: assets, isFetching } = useAssets(
    liveOnly,
    page,
    rowCount,
    queries.filter((q) => q.id !== filtersIdMap.STATUS),
    { enabled: canView },
  );

  const handleView = (id?: string) => id && navigate(`/assets/${id}`);

  const { isFetching: isFileFetching, refetch: downloadFile } = useExportAssets();

  let total = assets?.total || 0;
  const parsed =
    assets?.list?.map((item: Asset) => {
      return rowParser([
        item?.cli,
        item?.tag,
        formatCostCentreCode(item?.costCentre),
        item?.accountName,
        item?.productType,
        item?.status,
        <UIButton variant="text" color="primary" data-cy={`view-asset-${item?.cli}`} onClick={() => handleView(item?.id)}>
          View
        </UIButton>,
      ]);
    }) || [];

  useEffect(
    function handleFilterChange() {
      setPage(0);
    },
    [queries],
  );

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title={
        <>
          Search Assets
          <UIStack direction={"row"} spacing={1}>
            <UIButton
              variant="text"
              size="small"
              color="primary"
              data-cy="export-button"
              onClick={() => downloadFile()}
              startIcon={isFileFetching ? <UILoader size={20} /> : <DownloadIcon />}
              disabled={isFileFetching}
            >
              Export all to csv
            </UIButton>
          </UIStack>
        </>
      }
      data-cy="search-assets-page"
      needSelectedAccount={true}
      feature={Feature.AssetSearch}
      accessPermission={permissionCodes.VIEW_ASSETS}
      accessGrantedCb={() => setCanView(true)}
    >
      <UIGrid size={{ xs: 12, md: 12 }}>
        <UIAlert severity="info" className={classes.info}>
          View all the assets you have with us on this account. See details about your assets and assign them tags/usernames.
        </UIAlert>
        <UIGrid>
          <FiltersCard fetchHook={useAssetsFilters} hasReset={true} usage={ASSETS_FILTERS} />
        </UIGrid>
        {isFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>
            <div data-cy="asset-selection-table" className={classes.tableCtr}>
              <UIDataTable
                title=""
                data={parsed}
                columns={[
                  "CLI/Identifier",
                  "Tag",
                  "Cost Centre",
                  "Account",
                  "Product Type",
                  "Status",
                  { label: " ", name: "View" },
                ]}
                options={{
                  onTableChange: handleTableChange,
                  pagination: true,
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: total,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  serverSide: true,
                  viewColumns: false,
                  sort: false,
                  search: false,
                  selectableRows: "none",
                  rowHover: true,
                  setTableProps: () => ({
                    size: "small",
                  }),
                }}
              />
            </div>
          </>
        )}
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  tableCtr: {
    width: "100%",
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  info: {
    width: "fit-content",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
}));

export default SearchAssets;
