export const ACCOUNT_CONTEXT = "ACCOUNT_CONTEXT";
export const CONTEXT_ID = "CONTEXT_ID";

export function getContextId() {
  return localStorage.getItem(CONTEXT_ID) || "";
}

export function setContextId(id: string) {
  localStorage.setItem(CONTEXT_ID, id);
}
