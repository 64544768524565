import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import SignInRedirect from "../components/SignInRedirect";
import ForcePasswordChange from "./ForcePasswordChange";
import CognitoHeader from "../components/cognito/CognitoHeader";
import CognitoFooter from "../components/cognito/CognitoFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { I18n } from "aws-amplify/utils";
import { resetPassword, ResetPasswordInput } from "aws-amplify/auth";
import AuthLayout from "../layouts/AuthLayout";

I18n.putVocabulariesForLanguage("en", {
  "Send code": "Reset Password",
});

const Login = () => {
  const location = useLocation();
  const isInvite = location.pathname.includes("invite");
  const navigate = useNavigate();
  const { toSignIn, signOut } = useAuthenticator((context) => [context.user]);

  const services = {
    async handleForgotPassword(input: ResetPasswordInput) {
      signOut();
      input.options = {
        ...input.options,
        clientMetadata: {
          resetPasswordPageUrl: `${window.location.origin}/reset-password`,
        },
      };
      const output = await resetPassword(input);
      toSignIn();
      navigate("/auth/feedback", {
        state: {
          title: "Reset Password",
          type: "success",
          message:
            "We've sent you an email with a link to reset your password. Please check your inbox (and spam folder just in case) and follow the instructions to create a new password.",
        },
      });
      return output;
    },
  };

  const renderContent = () => {
    if (isInvite) {
      return <ForcePasswordChange />;
    }

    return (
      <Authenticator
        services={services}
        hideSignUp={true}
        loginMechanisms={["email"]}
        components={{
          Header: () => <CognitoHeader />,
          Footer: () => <CognitoFooter />,
        }}
      >
        {({ user }) => {
          return <SignInRedirect user={user} />;
        }}
      </Authenticator>
    );
  };

  return <AuthLayout title="Login">{renderContent()}</AuthLayout>;
};

export default Login;
