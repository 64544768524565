import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UIBox } from "../../_app/components";
import UIBack from "../../_app/components/UIBack";
import Main from "../../_app/layouts/Main";
import Tabbed from "../../_app/layouts/Tabbed";
import { createStylesheet } from "../../_app/utils/styles";
import BarringDetails from "../../bars/components/BarringDetails";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { Feature } from "../../feature/types";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import AssetUsageAlerts from "../components/AssetUsageAlerts";
import Details from "../components/details/Details";
import { useSingleAsset } from "../hooks";

export const AssetDetails = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data: asset, isLoading } = useSingleAsset(id ?? "");
  const [selectedTab, setSelectedTab] = useState("details");
  const hasBarsFeature = useHasAnyFeatureEnabled(Feature.Bars);
  const hasMobileManagementPermission = useHasPermission(permissionCodes.MOBILE_MANAGEMENT);
  const hasBars = Boolean(
    hasBarsFeature && hasMobileManagementPermission && asset?.assetType === "MOBILE" && asset?.status !== "Terminated",
  );
  const location = useLocation();

  const hasUsageAlertsAccessFeature = useHasAnyFeatureEnabled(Feature.UsageAlerts);
  const hasUsageAlertsPermission = useHasPermission(permissionCodes.ALERTS_ACCESS_FEATURE);
  const canViewUsageAlerts = hasUsageAlertsAccessFeature && hasUsageAlertsPermission;

  const onTabChange = (event: ChangeEvent<{}>, value: any) => {
    setSelectedTab(value);
  };

  // use history state when redirecting to the specific tab
  useEffect(() => {
    if (location.state && location.state.selectedTab !== undefined) {
      setSelectedTab(location.state.selectedTab);
    }
  }, [location.state]);

  const renderContent = () => {
    switch (selectedTab) {
      case "details":
        return asset && <Details asset={asset} showBars={() => setSelectedTab("barring")} hasBars={hasBars} />;
      case "barring":
        return <BarringDetails assetId={asset?.id} />;
      case "usage-alerts":
        return <AssetUsageAlerts assetId={id ?? ""} />;
      default:
        return null;
    }
  };

  return (
    <Main
      title="Asset Details"
      data-cy="asset-details-page"
      isLoading={isLoading}
      feature={Feature.AssetSearch}
      accessPermission={permissionCodes.VIEW_ASSETS}
      needSelectedAccount={true}
    >
      <UIBox width="100%">
        <UIBack btnProps={{ className: classes.back }} />
        <Tabbed
          selectedTab={selectedTab}
          tabs={[
            { value: "details", label: "Details", testId: "details-tab" },
            { value: "barring", label: "Barring", testId: "barring-tab", hidden: !hasBars },
            { value: "usage-alerts", label: "Usage Alerts", testId: "usage-alerts-tab", hidden: !canViewUsageAlerts },
          ]}
          handleTabChange={onTabChange}
        >
          <div className={classes.wrap}>{renderContent()}</div>
        </Tabbed>
      </UIBox>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  back: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tabList: {
    paddingBottom: theme.spacing(4),
  },
  wrap: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default AssetDetails;
