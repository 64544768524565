import { unassignedCostCentreModel } from "./api";
import { AccountLevels, CostCentre, FilterCostCentresResponse } from "./types";

export const formatCostCentreCode = (code?: string) => {
  if (code === unassignedCostCentreModel.code) {
    return unassignedCostCentreModel.name;
  } else if (code) {
    return code?.replaceAll?.(";", " | ");
  } else {
    return "";
  }
};

export const formatCostCentreOptions = (costCentres: CostCentre[]) => {
  const flattenFunc = (acc: any, cc: CostCentre) => {
    let options = [
      ...acc,
      {
        id: cc?.id,
        label: formatCostCentreCode(cc?.code),
        value: cc?.id,
      },
    ];
    if (cc?.subCostCentres?.length) {
      options = cc?.subCostCentres?.reduce(flattenFunc, options);
    }
    return options;
  };
  return [...flattenFunc([], unassignedCostCentreModel), ...(costCentres?.reduce(flattenFunc, []) || [])];
};

export const formatCCSearchResults = (searchResult?: FilterCostCentresResponse) => {
  const formatted: CostCentre[] = [];
  if (!searchResult) return formatted;
  if (searchResult.assetsWithoutCostCentre?.length) {
    formatted.push({
      ...unassignedCostCentreModel,
      assets: searchResult.assetsWithoutCostCentre.map((a) => ({ ...a, id: a.id || "", cli: a.cli || "" })),
    });
  }
  if (searchResult.costCentresWithAssets) formatted.push(...searchResult.costCentresWithAssets);
  return formatted;
};

export class AccountLevel {
  static readonly Group = new AccountLevel(AccountLevels.Group, "Group", 10);
  static readonly Company = new AccountLevel(AccountLevels.Company, "Company", 20);
  static readonly Account = new AccountLevel(AccountLevels.Account, "Account", 30);
  static readonly SubAccount = new AccountLevel(AccountLevels.SubAccount, "Sub Account", 35);
  static readonly Asset = new AccountLevel(AccountLevels.Asset, "Asset", 50);

  private constructor(
    public readonly value: AccountLevels,
    public readonly label: string,
    public readonly id: number,
  ) {}

  static fromString(level: AccountLevels): AccountLevel | undefined {
    switch (level) {
      case AccountLevels.Group:
        return AccountLevel.Group;
      case AccountLevels.Company:
        return AccountLevel.Company;
      case AccountLevels.Account:
        return AccountLevel.Account;
      case AccountLevels.SubAccount:
        return AccountLevel.SubAccount;
      case AccountLevels.Asset:
        return AccountLevel.Asset;
      default:
        return undefined; // Return undefined if no match is found
    }
  }
}
