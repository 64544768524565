import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UIButton, UIDivider, UIGrid, UIStep, UIStepLabel, UIStepper, UITypography } from "../../_app/components";
import {
  ArrowBack as ArrowBackIcon,
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForward as ArrowForwardIcon,
} from "../../_app/components/icons";
import Main from "../../_app/layouts/Main";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { scrollPage } from "../../_app/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { Feature } from "../../feature/types";
import { permissionCodes } from "../../permission/hooks";
import { useSubUserLevel } from "../../user-level/hooks";
import { SelectedAssets } from "../components/UsageAlerts/AlertAssetSelection";
import { SelectAction } from "../components/UsageAlerts/SelectAction";
import { SelectAssets } from "../components/UsageAlerts/SelectAssets";
import { SelectCriteria } from "../components/UsageAlerts/SelectCriteria";
import { useCreateNewAlert, useSingleUsageAlert, useUpdateUsageAlert } from "../hooks";
import { AlertCriteria, UsageAlert } from "../types";

enum STEP {
  ASSETS = "Select Assets",
  CRITERIA = "Criteria",
  ACTION = "Action",
}

export const EditUsageAlert = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { selectedAccount, currentAccountLevel } = useAccountContext();
  const isEdit = !!id;
  const subAccountUserLevel = useSubUserLevel()?.id || 0;
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({} as UsageAlert);
  const [isAssetSelected, setAssetSelected] = useState(isEdit);
  const selectedAssetsRef = useRef<SelectedAssets | null>(null);
  const steps = [...(isEdit ? [] : [STEP.ASSETS]), ...[STEP.CRITERIA, STEP.ACTION]];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    scrollPage();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollPage();
  };

  const handleReset = () => {
    setActiveStep(0);
    if (location?.state?.prevPath) {
      navigate(location.state.prevPath, { state: { selectedTab: "usage-alerts" } });
    } else {
      navigate("/usage-alerts");
    }
  };

  const { mutate: executeAddAlert, isLoading: addPending } = useCreateNewAlert({
    onSuccess: () => {
      handleNext();
    },
  });

  const { mutate: executeUpdateAlert, isLoading: updatePending } = useUpdateUsageAlert(id, {
    onSuccess: () => {
      handleNext();
    },
  });

  const { data: alert, isSuccess: alertSuccess } = useSingleUsageAlert(id, {
    enabled: isEdit,
  });

  useEffect(() => {
    if (alertSuccess && alert) {
      setData(alert);
    }
  }, [alertSuccess, alert]);

  useEffect(() => {
    if (!isEdit && currentAccountLevel?.id!! > subAccountUserLevel) {
      setData((data) => ({ ...data, level: "CLI" }));
    }
  }, [subAccountUserLevel, currentAccountLevel, isEdit]);

  const mapCriteria = (criteria?: AlertCriteria[]) => {
    return criteria?.map((c) => ({
      measure: c.measure?.name,
      threshold: c.threshold,
    }));
  };

  const handleSubmit = () => {
    const clis = data.level === "CLI" ? selectedAssetsRef.current?.assets?.map((a) => a.cli || "") || [""] : [""];
    const filters = selectedAssetsRef.current?.filters;
    if (isEdit && data.id) {
      executeUpdateAlert({
        alertId: data.id,
        alertDetailUpdateConfig: {
          name: data.name,
          alertAction: {
            bar: data.alertAction?.bar?.name,
            contacts: data.alertAction?.contacts || [],
            notifyAssetsBySMS: data.alertAction?.notifyAssetsBySMS,
          },
          criteria: mapCriteria(data.criteria),
        },
      });
    } else {
      executeAddAlert({
        ...data,
        alertAction: {
          bar: data.alertAction?.bar?.name,
          contacts: data.alertAction?.contacts || [],
          notifyAssetsBySMS: data.alertAction?.notifyAssetsBySMS,
        },
        criteria: mapCriteria(data.criteria),
        accountId: selectedAccount?.id!!,
        cli: clis,
        level: data.level || "CLI",
        product: data.product || "",
        name: data?.name || "",
        filters,
      });
    }
  };

  const isStepActive = (step: STEP) => steps[activeStep] === step;

  const toggleStepContents = (step: STEP) => {
    return { style: { display: isStepActive(step) ? "block" : "none" } };
  };

  const hasContactWithNotify = data?.alertAction?.contacts?.some((c) => c.emailNotification || c.smsNotification);
  const hasNotify = data?.alertAction?.notifyAssetsBySMS || hasContactWithNotify;
  const hasCliBar = data.product === "MOBILE" && data.level === "CLI" ? Boolean(data?.alertAction?.bar) : true;

  const isSubmitStep = activeStep === steps.length - 1;
  const canSubmit = hasNotify && hasCliBar;
  const isRequestPending = isEdit ? updatePending : addPending;

  return (
    <Main
      data-cy="add-edit-usage-alert-page"
      feature={Feature.UsageAlerts}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount={true}
    >
      <UIGrid className={classes.stepper}>
        <UIStepper activeStep={activeStep}>
          {steps.map((label) => (
            <UIStep key={label}>
              <UIStepLabel>
                <UITypography className={classes.bold} data-cy="step">
                  {label}
                </UITypography>
              </UIStepLabel>
            </UIStep>
          ))}
        </UIStepper>
      </UIGrid>
      <UIGrid container direction="column" justifyContent="center" className={classes.wrap}>
        {activeStep === steps.length ? (
          <UIGrid
            size={{ xs: 12 }}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "70vh" }}
          >
            <UITypography variant="h3" className={classes.instructions}>
              All changes submitted
            </UITypography>
            <UIButton variant="contained" color="primary" onClick={handleReset}>
              Back
            </UIButton>
          </UIGrid>
        ) : (
          <>
            <UIDivider variant="fullWidth" />
            <div className={classes.contentCtr}>
              <div {...toggleStepContents(STEP.ASSETS)}>
                <SelectAssets
                  usageAlert={data}
                  onInputChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const key = e.target.name;
                    const val = e.target.value;
                    if (key === "level" && val === "ACCOUNT" && data.alertAction?.bar) {
                      delete data.alertAction.bar;
                    }
                    setData({ ...data, [key]: val });
                  }}
                  defaultSelected={undefined}
                  setSelectedAssets={(selected: SelectedAssets) => {
                    setAssetSelected(!!selected.assets?.length || !!selected.filters?.length);
                    selectedAssetsRef.current = selected;
                  }}
                  hasLevelOptions={currentAccountLevel?.id!! <= subAccountUserLevel}
                  hasProductOptions={!isEdit}
                />
              </div>
              <div {...toggleStepContents(STEP.CRITERIA)}>
                <SelectCriteria usageAlert={data} setAlert={setData} isEdit={isEdit} />
              </div>
              <div {...toggleStepContents(STEP.ACTION)}>
                <SelectAction usageAlert={data} setAlert={setData} />
              </div>
            </div>
            <div className={classes.stepperActions}>
              {!activeStep && (
                <UIButton variant="text" color="primary" onClick={handleReset} data-cy="back-initial">
                  <ArrowBackIosIcon fontSize="small" /> Back
                </UIButton>
              )}
              <UIButton
                disabled={activeStep === 0}
                style={{ opacity: Math.min(activeStep, 1) }}
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
                className={classes.backButton}
                data-cy="back"
              >
                Back
              </UIButton>
              <UIButton
                variant="contained"
                onClick={isSubmitStep ? handleSubmit : handleNext}
                color="primary"
                isLoading={isRequestPending}
                endIcon={!isRequestPending ? <ArrowForwardIcon /> : null}
                data-cy="next"
                disabled={(function actionButtonIsDisabled() {
                  if (isStepActive(STEP.ASSETS)) {
                    if (!data?.level || !data?.product || !data?.name || (data?.level === "CLI" && !isAssetSelected)) return true;
                  }
                  if (isStepActive(STEP.CRITERIA)) {
                    if (!data?.criteria?.[0].measure?.name || !data?.name || !data?.criteria?.[0].threshold) return true;
                  }
                  if (isSubmitStep && !canSubmit) return true;
                  if (isRequestPending) return true;
                  return false;
                })()}
              >
                {(function actionButtonLabel() {
                  if (!isSubmitStep) return "Continue";
                  return isRequestPending ? "Submitting request…" : "Submit";
                })()}
              </UIButton>
            </div>
          </>
        )}
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  wrap: {
    width: "100%",
  },
  stepper: {
    padding: "0px 0px 10px",
    width: "100%",
    "& > div": {
      padding: 0,
    },
  },
  contentCtr: {
    width: "100%",
    paddingTop: 18,
    marginBottom: 10,
  },
  stepperActions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 600,
  },
}));

export default EditUsageAlert;
