import { useEffect, useState } from "react";
import Main from "../../_app/layouts/Main";
import { Feature } from "../../feature/types";
import { permissionCodes } from "../../permission/hooks";
import ManageBundleAlertsTabBar from "../components/BundleAlerts/ManageBundleAlertsTabBar";
import {
  useBundleAlertsIndividual,
  useUpdateBundleAlertsIndividuals,
  useUpdateBundleAlertsIndividualsDisableAll,
  useUpdateBundleAlertsIndividualsEnableAll,
} from "../hooks";
import { genericError } from "../../_app/utils/text";
import { useFeedbackAlerts } from "../../_app/hooks";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import FiltersCard from "../../filter/components/FiltersCard";
import { useBundleAlertsFilters, useFilterQueries } from "../../filter/hooks";
import ToggleButtons from "../../form/components/ToggleButtons";
import { BundleAlertIndividual, BundleAlertUpdate } from "../types";
import { formatCostCentreCode } from "../../account/utils";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIFormControlLabel, UIGrid, UISwitch } from "../../_app/components";
import UIDataTable, { UIDataTableState } from "../../_app/components/Table/UIDataTable";

export const UsageAlerts = () => {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const classes = useStyles();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [allOperation, setAllOperation] = useState("");

  const [cliList, setCliList] = useState<any>();
  const [dirtyCliList, setDirtyCliList] = useState<any>({});

  const queries = useFilterQueries();

  const { data: clis, isFetching } = useBundleAlertsIndividual(page, rowCount, queries.length ? queries : undefined);

  useEffect(() => {
    if (queries.length && allOperation) setAllOperation("");
  }, [queries, allOperation]);

  const resultHandle = {
    onSuccess: (response: string) => {
      setFeedbackAlertSuccess(response);
      reset();
    },
    onError: (response: string) => {
      setFeedbackAlertError(response || genericError());
      reset();
    },
  };

  const updateBundleAlertsAccounts = useUpdateBundleAlertsIndividuals(resultHandle);
  const updateBundleAlertsAccountsEnableAll = useUpdateBundleAlertsIndividualsEnableAll(resultHandle);
  const updateBundleAlertsAccountsDisableAll = useUpdateBundleAlertsIndividualsDisableAll(resultHandle);

  const handleUpdateAction = () => {
    const updatedBundleAlerts = dirtyCliList ? Object.values(dirtyCliList) : [];
    if (allOperation) {
      if (allOperation === "enabled-all") {
        updateBundleAlertsAccountsEnableAll.mutate(undefined);
      } else {
        updateBundleAlertsAccountsDisableAll.mutate(undefined);
      }
    } else {
      updateBundleAlertsAccounts.mutate(updatedBundleAlerts as BundleAlertUpdate[]);
    }
  };

  const reset = () => {
    setDirtyCliList({});
    setAllOperation("");
  };

  const handleChange = (id: any, event: any) => {
    const updates = { ...dirtyCliList };
    if (updates[id]) {
      delete updates[id];
    } else {
      updates[id] = { id, [event.target.name]: event.target.checked };
    }
    setDirtyCliList(updates);
  };

  useEffect(() => {
    clis?.list?.map((item: BundleAlertIndividual) => {
      return setCliList((old: any) => {
        return {
          ...old,
          [item.assetId]: { id: item.assetId, enabled: item.enabled },
        };
      });
    });
  }, [clis]);

  const parsed: any =
    (cliList &&
      clis?.list?.map((item: BundleAlertIndividual) => {
        let value = cliList[item.assetId]?.enabled;
        if (allOperation === "enabled-all") {
          value = true;
        } else if (allOperation === "disabled-all") {
          value = false;
        } else if (dirtyCliList[item.assetId]) {
          value = dirtyCliList[item.assetId]?.enabled;
        }
        return rowParser([
          item.cli,
          item.tag,
          formatCostCentreCode(item?.costCentre),
          <UIFormControlLabel
            control={<UISwitch checked={value} onChange={(e) => handleChange(item.assetId, e)} name="enabled" color="primary" />}
            label={value ? "Enabled" : "Disabled"}
          />,
        ]);
      })) ||
    [];

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setCliList(undefined);
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setCliList(undefined);
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  useEffect(
    function handleFilterChange() {
      setPage(0);
    },
    [queries],
  );

  return (
    <Main
      title="Bundle Alerts"
      data-cy="support-bundle-alerts-page"
      feature={Feature.BundleAlerts}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount={true}
    >
      <ManageBundleAlertsTabBar selectedTab="bundle-alerts">
        <UIGrid size={{ xs: 12, md: 12 }}>
          {isFetching ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <>
              <FiltersCard fetchHook={useBundleAlertsFilters} hasReset={false} hasDownload={false} />
              <div className={classes.tableCtr} data-cy="bundle-alerts-table">
                <UIGrid container justifyContent="flex-end" className={classes.actions}>
                  <UIButton
                    variant="contained"
                    color="primary"
                    data-cy="save-bundle-alert-button"
                    onClick={() => handleUpdateAction()}
                    disabled={(!Object.keys(dirtyCliList).length && !allOperation) || !clis?.list.length}
                  >
                    Save Changes
                  </UIButton>
                </UIGrid>
                {!Boolean(queries?.length) && (
                  <UIGrid container justifyContent="flex-end" className={classes.actions}>
                    <ToggleButtons
                      value={allOperation}
                      onChange={(_, value) => setAllOperation(value)}
                      options={[
                        { label: "Enable All", value: "enabled-all" },
                        { label: "Disable All", value: "disabled-all" },
                      ]}
                    />
                  </UIGrid>
                )}
                <UIDataTable
                  title=""
                  data={parsed}
                  columns={["Number", "Tag", "Cost Centre", ""]}
                  options={{
                    page,
                    rowsPerPage: rowCount,
                    rowsPerPageOptions: rowCountOptions,
                    count: clis?.total,
                    onTableChange: handleTableChange,
                    pagination: true,
                    download: false,
                    elevation: 1,
                    print: false,
                    responsive: "standard",
                    selectToolbarPlacement: "none",
                    filter: false,
                    viewColumns: false,
                    sort: false,
                    search: false,
                    rowHover: true,
                    serverSide: true,
                    selectableRows: "none",
                    selectableRowsHeader: false,
                    setTableProps: () => ({ size: "small" }),
                    setRowProps: (row) => ({
                      "data-cy": `bundle-alert-cli-id-${row?.[0]}`,
                    }),
                  }}
                />
              </div>
            </>
          )}
        </UIGrid>
      </ManageBundleAlertsTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  actions: {
    marginBottom: theme.spacing(2),
  },
  tableCtr: {},
}));

export default UsageAlerts;
