import api, { IdValueQuery } from "../_app/api";
import { convertFiltersToQueries } from "../filter/api";
import {
  Address,
  Asset,
  BillLimit,
  ClearBillLimitParams,
  GetAssetsOperationHistoryResponse,
  GetAssetsResponse,
  GetBillLimitPrefsResponse,
  GetClisResponse,
  LineTestResponse,
  MobileAsset,
  MobileDetails,
  MobileNetwork,
  SearchAsset,
  ServiceSetting,
  UpdateBillLimitParams,
  UpdateBillLimitPrefsParams,
} from "./types";
import { Page } from "../_app/api/types";

export const getAssetById = async (id: string) => {
  const { data }: { data: Asset } = await api({
    method: "GET",
    url: `/assets/${id}`,
  });
  return data;
};

export const getAssets = async (liveOnly?: boolean, page?: number, limit?: number, queries?: any[]) => {
  const { data }: { data: GetAssetsResponse } = await api({
    method: "GET",
    url: liveOnly ? "/assets/live" : "/assets",
    context_injection: true,
    serialize: true,
    params: { page, size: limit, queries },
  });
  return data;
};

export const getAssetsByCostCentre = async (cc: string, page?: number, size?: number) => {
  let { data }: { data: GetAssetsResponse } = await api({
    method: "GET",
    url: `/assets/cost-centre/${cc}`,
    context_injection: true,
    params: { page, size },
  });
  return data;
};

export const getAssetsCsv = async () => {
  const response = await api({
    method: "GET",
    url: "/assets/export",
    context_injection: true,
    responseType: "arraybuffer",
  });
  return response;
};

export const getClis = async (query?: string, page: number = 0, pageSize: number = 20) => {
  const { data }: { data: GetClisResponse } = await api({
    method: "GET",
    url: "/filters/CALL_ANALYSIS/values/clis",
    context_injection: true,
    params: { size: pageSize, "search-term": query, page },
  });
  return data;
};

export const performLineTest = async (cli: string) => {
  const { data }: { data: LineTestResponse } = await api({
    method: "POST",
    url: "/support/line-test",
    context_injection: true,
    params: {
      "telephone-number": cli,
    },
  });
  return data;
};

export const getServiceSettings = async (assetId: string) => {
  // @todo - replace with actual api call
  // let { data }: { data: ServiceSettings } = await api({
  //   method: "GET",
  //   context_injection: true,
  //   url: "/asset/service-settings",
  //   params: { assetId }
  // });
  const data: ServiceSetting[] = [
    {
      id: "1",
      description: "Bundle Notification",
      value: "Opted In",
    },
    {
      id: "2",
      description: "Worldwide Data Roaming",
      value: "Uncapped",
    },
    {
      id: "3",
      description: "Last Call Date",
      value: "2021-02-01",
    },
    {
      id: "4",
      description: "Divert on Busy",
      value: "447665577889",
    },
    {
      id: "5",
      description: "Divert Immediately",
      value: "447665577889",
    },
    {
      id: "5",
      description: "Divert No Reply",
      value: "447665577889",
    },
    {
      id: "6",
      description: "Divert Not Reachable",
      value: "447665577889",
    },
    {
      id: "7",
      description: "4G Allowed",
      value: "YES",
    },
    {
      id: "8",
      description: "5G Service",
      value: "YES",
    },
    {
      id: "9",
      description: "Conference Calling value",
      value: "YES",
    },
    {
      id: "10",
      description: "Voicemail",
      value: "YES",
    },
    {
      id: "11",
      description: "Wifi Calling",
      value: "YES",
    },
    {
      id: "12",
      description: "MMS Service",
      value: "NO",
    },
  ];
  return data;
};

export const getServiceDetails = async (assetId: string, page: number, limit: number) => {
  // @todo - replace with actual api call
  // let { data }: { data: any } = await api({
  //   method: "GET",
  //   context_injection: true,
  //   url: "/asset/service",
  //   params: { assetId, page, "size": limit }
  // });
  const data = {
    list: [
      {
        id: "123",
        description: "Desc 1",
        startDate: "2021-02-02",
        endDate: "2021-04-02",
        quantity: "5",
        frequency: "Monthly",
        cost: "£20",
      },
      {
        id: "456",
        description: "Desc 2",
        startDate: "2021-01-01",
        endDate: "2021-06-06",
        quantity: "3",
        frequency: "Monthly",
        cost: "£10",
      },
      {
        id: "789",
        description: "Desc 3",
        startDate: "2021-04-04",
        endDate: "2021-06-10",
        quantity: "1",
        frequency: "Monthly",
        cost: "£5",
      },
    ],
    page: 1,
    pageSize: 25,
    total: 10,
  };
  return data;
};

export const getMobileDetailsByAssetId = async (assetId: string) => {
  const { data }: { data: MobileDetails } = await api({
    method: "GET",
    url: `/assets/mobile/${assetId}/details`,
  });
  return data;
};

export const getMobileAssets = async (accountId: string, page?: number, limit?: number, queries?: any[], clis?: string) => {
  const { data }: { data: Page<MobileAsset> } = await api({
    method: "GET",
    url: `/mobile-assets/accounts/${accountId}`,
    serialize: true,
    params: {
      page,
      size: limit,
      queries,
      clis,
    },
  });
  return data;
};

export const getMobileAssetsForCostCentre = async (costCentreId: string, page?: number, limit?: number, queries?: any[]) => {
  const { data }: { data: Page<MobileAsset> } = await api({
    method: "GET",
    url: `/mobile-assets/cost-centres/${costCentreId}`,
    serialize: true,
    params: {
      page,
      size: limit,
    },
  });
  return data;
};

export const getMobileAssetsWithEligibility = async (page?: number, limit?: number, queries?: any[], clis?: string) => {
  const { data }: { data: Page<MobileAsset> } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/mobile-with-eligibility",
    serialize: true,
    params: {
      page,
      size: limit,
      queries,
      clis,
    },
  });
  return data;
};

export const getLineAssetClis = async (page?: number, limit?: number, query?: string) => {
  const { data }: { data: GetClisResponse } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/line",
    serialize: true,
    params: { page, size: limit, query },
  });
  return data;
};

export const orderSim = async (assetId: string, address: Address, simType: string) => {
  const { data }: { data: string } = await api({
    method: "POST",
    url: `/mobile-assets/${assetId}/sim/order`,
    data: {
      deliveryAddress: address,
      simType: simType,
    },
  });
  return data;
};

export const orderSimCostCentre = async (costCentreId: string, assetId: string, address: Address, simType: string) => {
  const { data }: { data: string } = await api({
    method: "POST",
    url: `/mobile-assets/${assetId}/cost-centers/${costCentreId}/sim/order`,
    data: {
      deliveryAddress: address,
      simType: simType,
    },
  });
  return data;
};

export const getMobileNetworks = async (accountId: string, assetIds?: string[], queries?: IdValueQuery[]) => {
  const { data }: { data: MobileNetwork[] } = await api({
    method: "GET",
    url: `/mobile-assets/accounts/${accountId}/networks`,
    serialize: true,
    params: { assetIds, queries },
  });
  return Array.from(new Set(data?.filter(Boolean)));
};

export const getMobileNetworksForCostCentres = async (costCentreId: string, assetIds?: string[], queries?: IdValueQuery[]) => {
  const { data }: { data: MobileNetwork[] } = await api({
    method: "GET",
    url: `/mobile-assets/cost-centres/${costCentreId}/networks`,
    serialize: true,
    params: { assetIds, queries },
  });
  return Array.from(new Set(data?.filter(Boolean)));
};

export const getAssetsOperationHistory = async (accountId: string, startingToken: string | undefined, limit: number) => {
  const { data }: { data: GetAssetsOperationHistoryResponse } = await api({
    method: "GET",
    url: `/mobile-management/accounts/${accountId}/history`,
    params: {
      size: limit,
      "starting-token": startingToken,
    },
  });
  return data;
};

export const getAssetsOperationHistoryCostCentre = async (
  costCentreId: string,
  startingToken: string | undefined,
  limit: number,
) => {
  const { data }: { data: GetAssetsOperationHistoryResponse } = await api({
    method: "GET",
    url: `/mobile-management/cost-centres/${costCentreId}/history`,
    params: {
      size: limit,
      "starting-token": startingToken,
    },
  });
  return data;
};

export const getBillLimit = async (accountId: string, assetIds: string[], page?: number, limit?: number, queries?: any[]) => {
  const { data }: { data: Page<BillLimit> } = await api({
    method: "GET",
    url: `/mobile-assets/accounts/${accountId}/bill-limits`,
    serialize: true,
    params: { page, size: limit, queries, "asset-ids": assetIds },
  });
  return data;
};

export const getBillLimitCostCentre = async (
  costCentreId: string,
  assetIds: string[],
  page?: number,
  limit?: number,
  queries?: any[],
) => {
  const { data }: { data: Page<BillLimit> } = await api({
    method: "GET",
    url: `/mobile-assets/cost-centres/${costCentreId}/bill-limits`,
    serialize: true,
    params: { page, size: limit, queries, "asset-ids": assetIds },
  });
  return data;
};

export const updateBillLimit = async (accountId: string, params: UpdateBillLimitParams) => {
  const parsedParams = convertFiltersToQueries(params);
  if (parsedParams.queries) delete parsedParams.assetIds;

  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/accounts/${accountId}/bill-limits`,
    data: parsedParams,
  });
  return data;
};

export const updateBillLimitCostCentre = async (costCentreId: string, params: UpdateBillLimitParams) => {
  const parsedParams = convertFiltersToQueries(params);
  if (parsedParams.queries) delete parsedParams.assetIds;

  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/cost-centres/${costCentreId}/bill-limits`,
    data: parsedParams,
  });
  return data;
};

export const clearBillLimit = async (accountId: string, params: ClearBillLimitParams) => {
  const parsedParams = convertFiltersToQueries(params);
  if (parsedParams.queries) delete parsedParams.assetIds;

  const { data }: { data: boolean } = await api({
    method: "DELETE",
    url: `/mobile-assets/accounts/${accountId}/bill-limits`,
    data: parsedParams,
  });

  return data;
};

export const clearBillLimitCostCentre = async (costCentreId: string, params: ClearBillLimitParams) => {
  const parsedParams = convertFiltersToQueries(params);
  if (parsedParams.queries) delete parsedParams.assetIds;

  const { data }: { data: boolean } = await api({
    method: "DELETE",
    url: `/mobile-assets/cost-centres/${costCentreId}/bill-limits`,
    data: parsedParams,
  });

  return data;
};

export const getBillLimitPrefs = async (accountId: string, assetId?: string) => {
  const { data }: { data: GetBillLimitPrefsResponse } = await api({
    method: "GET",
    url: `/mobile-assets/accounts/${accountId}/bill-limits/notification-prefs`,
    params: {
      "asset-id": assetId,
    },
  });
  return data;
};

export const updateBillLimitPrefs = async (accountId: string, params: UpdateBillLimitPrefsParams) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/accounts/${accountId}/bill-limits/notification-prefs`,
    data: params,
  });
  return data;
};

export const updateTag = async ({ assetId, tag }: { assetId: string; tag: string }) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/assets/${assetId}/tag`,
    params: { tag },
  });
  return data;
};

export const searchAssets = async (page: number, searchTerm: string) => {
  const { data }: { data: Page<SearchAsset> } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/search",
    serialize: true,
    params: { page, "search-term": searchTerm },
  });

  return data;
};
