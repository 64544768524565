import { UIEvent, useEffect, useRef } from "react";
import { Info as InfoIcon } from "../../_app/components/icons";
import { formatDate } from "../../_app/utils/format";
import { useTicketComments } from "../hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UIChip, UIGrid, UIHtml, UILoader, UISkeleton, UITimelineDot, UITypography } from "../../_app/components";
import { TicketComment } from "../types";

interface Props {
  ticketId: string;
  scrollToTop?: number;
}

export const CommentsList = ({ ticketId, scrollToTop }: Props) => {
  const classes = useStyles();
  const ref = useRef<null | HTMLDivElement>(null);
  const { data: commentsResp, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } = useTicketComments(ticketId);
  const comments = commentsResp?.pages.reduce<TicketComment[]>((acc, page) => [...acc, ...page.list], []);
  useEffect(() => {
    if (scrollToTop) performScrollToTop();
  }, [scrollToTop]);

  const performScrollToTop = () => ref?.current?.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <UICard
      innerRef={ref}
      elevation={1}
      className={classes.ctr}
      height="fit-content"
      data-cy="ticket-notes-card"
      onScroll={(e: UIEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        const buffer = 15;
        const scrolled = target.scrollHeight - target.scrollTop;
        const total = target.clientHeight + buffer;
        const hitBottom = scrolled <= total;
        if (hitBottom && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
    >
      {!comments?.length && !isFetching && (
        <div className={classes.emptyCtr}>
          <UITypography variant="body1">No comments</UITypography>
        </div>
      )}
      {comments?.map((_comment) => {
        const isPending = !_comment.created;
        return (
          <UICard
            key={_comment.id}
            elevation={1}
            className={`${classes.commentItem} ${isPending ? classes.pendingCommentItem : ""}`}
          >
            <UIGrid container alignItems="center" className={classes.grid}>
              <UITimelineDot color={!isPending ? "primary" : "grey"} />
              <UITypography component="span" variant="subtitle2" className={classes.marginLeft}>
                {!isPending ? formatDate(_comment.created, "dd/MM/yyyy HH:mm:ss") : <UISkeleton width={100} />}
              </UITypography>
            </UIGrid>
            <UIGrid container className={classes.grid}>
              <UITypography component="span" variant="h3" className={classes.createdBy}>
                {_comment.createdBy}
              </UITypography>
              {Boolean(_comment.internal) && (
                <UIChip
                  variant="outlined"
                  color="primary"
                  label="Daisy Customer Services"
                  size="small"
                  icon={<InfoIcon />}
                  className={classes.marginLeft}
                />
              )}
            </UIGrid>
            <UIGrid className={classes.grid}>
              <UIHtml html={_comment.comment} />
            </UIGrid>
          </UICard>
        );
      })}
      {isFetching && (
        <div className={classes.emptyCtr}>
          <UILoader />
        </div>
      )}
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  emptyCtr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ctr: {
    maxHeight: "calc(100vh - 260px)",
    padding: theme.spacing(2),
    overflow: "auto",
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  grid: {
    paddingBottom: theme.spacing(2),
  },
  createdBy: {
    fontWeight: 700,
  },
  commentItem: {
    borderRadius: "5px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderLeft: "3px solid",
    borderColor: theme.palette.primary.main,
  },
  pendingCommentItem: {
    borderColor: theme.palette.grey[400],
  },
}));

export default CommentsList;
