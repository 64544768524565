import { PasswordField } from "@aws-amplify/ui-react";
import { createPasswordSchema, formatMultilineErrorMessage } from "../../utils";
import { useForm } from "react-hook-form";
import { ChangePasswordForm, PasswordPolicy } from "../../types";
import { zodResolver } from "@hookform/resolvers/zod";
import UIButton from "../../../_app/components/UIButton";
import UICard from "../../../_app/components/UICard";
import { UIBox, UITypography } from "../../../_app/components";

interface CognitoPasswordChangeFormProps {
  heading: string;
  passwordPolicy: PasswordPolicy | undefined;
  onSubmit: (input: ChangePasswordForm) => void;
}

const CognitoPasswordChangeForm = ({ heading, onSubmit, passwordPolicy }: CognitoPasswordChangeFormProps) => {
  const schema = createPasswordSchema(passwordPolicy);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordForm>({
    resolver: zodResolver(schema),
    mode: "all",
  });

  return (
    <UICard sx={{ borderRadius: 0 }}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <UIBox component="div" display="flex" flexDirection="column" alignItems="stretch" gap="1rem" height="100%">
          <UITypography variant="h6" fontWeight={"semibold"} textAlign={"center"}>
            {heading}
          </UITypography>

          <PasswordField
            label="Password"
            placeholder="Enter your Password"
            {...register("password")}
            hasError={!!errors.password}
            errorMessage={formatMultilineErrorMessage(errors.password?.message)}
          />

          <PasswordField
            label="Confirm Password"
            placeholder="Please confirm your Password"
            {...register("confirmPassword")}
            hasError={!!errors.confirmPassword}
            errorMessage={formatMultilineErrorMessage(errors.confirmPassword?.message)}
          />

          <UIButton type={"submit"}>Change Password</UIButton>
        </UIBox>
      </form>
    </UICard>
  );
};

export default CognitoPasswordChangeForm;
