import { ParamsWithQueryFilters } from "./../filter/types";
import { PaginatedResponse } from "./../_app/api/types";
import { Endpoints, Schemas } from "../_app/api/types";
import { Modify } from "../_app/utils/types";
import { AccountLevel } from "./utils";

export type Group = Schemas["GroupWithUserAccessibleAccountsDTO"];

export type AccessibleAccountDto = Schemas["AccessibleAccountDto"];

export type Account = Schemas["AccountDTO"];

export type GetAccountsParams = Modify<
  Endpoints["getAccounts"]["parameters"]["query"],
  {
    parent: string;
    "search-query"?: string;
  }
>;

export type GetAccountsResponse = PaginatedResponse<Account>;

export type BillingAddress = Schemas["AddressDTO"];

export type BillingAddressUpdateParams = Omit<BillingAddress, "countries">;

export type BillSettings = Schemas["BillSettingDTO"];

export type CostCentre = Schemas["CostCentreDTO"] & { assets?: Schemas["BasicAssetInfoDto"][] };

export type FilterCostCentresResponse = Schemas["SearchAssetsResultDto"];
export interface CostCentreWithAccount {
  costCentreId: string;
  costCentreName: string;
  accountId: string;
}

export type FilterCostCentresParams = {
  search: string;
};

export type CostCentreAssignParams = ParamsWithQueryFilters<Schemas["CostCentreAssignDto"]>;

export interface AccountHierarchy {
  level: AccountLevel;
  hierarchy: Record<AccountLevels, AccountInfo>;
}

export type AccountInfo = Modify<Schemas["AccessibleAccountDto"], { level: AccountLevels }>;
export interface StoredAccountHierarchy {
  context: AccountHierarchy;
  userId: string;
}

export enum AccountLevels {
  Group = "GROUP",
  Company = "COMPANY",
  Account = "ACCOUNT",
  SubAccount = "SUB_ACCOUNT",
  Asset = "ASSET",
}
