import { useMemo } from "react";
import { UIGrid } from "../../_app/components";
import { rowParser } from "../../_app/components/Table/helpers";
import { LoadMoreFooter } from "../../_app/components/Table/LoadMoreFooter";
import UIDataTable from "../../_app/components/Table/UIDataTable";
import UILoader from "../../_app/components/UILoader";
import Main from "../../_app/layouts/Main";
import { formatDate, humanize, parseStringToDate } from "../../_app/utils/format";
import { createStylesheet } from "../../_app/utils/styles";
import { Feature } from "../../feature/types";
import FiltersCard from "../../filter/components/FiltersCard";
import { useFilterQueries, useTriggeredAlertsFilters } from "../../filter/hooks";
import { Query } from "../../filter/types";
import { permissionCodes } from "../../permission/hooks";
import UsageAlertsTabBar from "../components/UsageAlerts/UsageAlertsTabBar";
import { useUsageAlertsHistory } from "../hooks";
import { UsageAlertHistory } from "../types";

export interface SelectedRows {
  index?: string;
  dataIndex?: string;
}

export const UsageAlertsHistory = () => {
  const classes = useStyles();
  const rowCount = 25;
  const queries = useFilterQueries();

  const mapDateRange = (queries: Query[]) => {
    if (queries && queries.length === 2) {
      return [
        {
          id: "from",
          value: formatDate(parseStringToDate(queries[0].value, "dd/MM/yyyy"), "yyyy-MM-dd"),
        },
        {
          id: "to",
          value: formatDate(parseStringToDate(queries[1].value, "dd/MM/yyyy"), "yyyy-MM-dd"),
        },
      ];
    }
    return [];
  };

  const { data: usageAlerts, isFetching, fetchNextPage, hasNextPage } = useUsageAlertsHistory(rowCount, mapDateRange(queries));

  const parsed = useMemo(() => {
    const pages: any = usageAlerts?.pages?.map((page) => {
      return page?.list?.map((item: UsageAlertHistory) =>
        rowParser([
          item.cli,
          item.name,
          humanize(item.product),
          `${item.criteria?.[0]?.measure?.value || "-"} greater than ${item.criteria?.[0]?.threshold || "-"}`,
          item.alertAction?.bar?.value || "-",
          item.alertAction?.contacts?.map((c: any) => `${c.forename || "-"} ${c.surname || "-"}`).join(", "),
          formatDate(item.alertBreach?.alertedOn, "dd/MM/yyyy"),
          `${item.alertBreach?.usageValue} ${item.alertBreach?.usageValueUnit} (${item.criteria?.[0]?.measure?.value})`,
        ]),
      );
    });
    return [].concat.apply([], pages);
  }, [usageAlerts]);

  return (
    <Main
      title="Usage Alerts"
      data-cy="support-usage-alerts-history"
      feature={Feature.UsageAlerts}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      needSelectedAccount
    >
      <UsageAlertsTabBar selectedTab="usage-alerts-history">
        <div className={classes.filtersCtr} data-cy="usage-alerts-history-filters">
          <FiltersCard fetchHook={useTriggeredAlertsFilters} hasReset={true} />
        </div>
        <UIGrid size={{ xs: 12, md: 12 }}>
          {isFetching ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div className={classes.tableCtr}>
              <UIDataTable
                title=""
                data={parsed}
                columns={["Identifier", "Name", "Product", "Description", "Action", "Recipients", "Date", "Triggered Value", ""]}
                options={{
                  rowsPerPage: rowCount,
                  pagination: false,
                  customFooter: () => {
                    return <LoadMoreFooter disabled={isFetching} hasMore={Boolean(hasNextPage)} onChangePage={fetchNextPage} />;
                  },
                  serverSide: true,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  viewColumns: false,
                  sort: false,
                  search: false,
                  rowHover: true,
                  selectableRows: "none",
                  selectableRowsHeader: false,
                  setTableProps: () => ({ size: "small" }),
                  setRowProps: (row) => ({
                    "data-cy": `usage-alert-history-id-${row?.[0]}`,
                  }),
                }}
              />
            </div>
          )}
        </UIGrid>
      </UsageAlertsTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  actions: {
    marginTop: theme.spacing(3),
    marginBottom: "15px",
    gap: "10px",
  },
  tableActions: {
    width: "100%",
    marginTop: 10,
    padding: 12,
    paddingLeft: 27,
    "& button": {
      marginLeft: 12,
    },
  },
  tableCtr: {},
  filtersCtr: {
    marginBottom: theme.spacing(3),
  },
}));

export default UsageAlertsHistory;
