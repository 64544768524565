import { useCallback, useEffect, useMemo, useState } from "react";
import { IdValueQuery } from "../../../_app/api";
import { useLiveAssets } from "../../../asset/hooks";
import { useAllFilters } from "../../../filter/hooks";
import { useQueryParams } from "../../../_app/hooks";
import FiltersCard from "../../../filter/components/FiltersCard";
import SelectAllHeader from "../../../_app/components/Table/SelectAllHeader";
import UILoader from "../../../_app/components/UILoader";
import { Asset } from "../../../asset/types";
import { createStylesheet } from "../../../_app/utils/styles";
import { UIGrid } from "../../../_app/components";
import UIDataTable from "../../../_app/components/Table/UIDataTable";

export interface Props {
  defaultSelected?: string[] | string | null;
  setSelectedAssets: (...args: any[]) => any;
  usageAlert: any;
}

export interface SelectedAssets {
  assets?: Asset[];
  filters?: IdValueQuery[];
}

const FILTERS_KEY: string = "ASSETS";

// @todo - implement pre-selected filters from edit screen

export const AlertAssetSelection = ({ setSelectedAssets, defaultSelected, usageAlert }: Props) => {
  const classes = useStyles();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const queryParams = useQueryParams();
  const getQueries = useCallback(() => {
    const filters: any[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      values.forEach((query) => {
        filters.push({
          id: key,
          value: query,
        });
      });
    });
    filters.push({
      id: "45",
      value: usageAlert.product,
    });
    return filters;
  }, [queryParams, usageAlert.product]);

  const { data: assetsResp, isFetching } = useLiveAssets(page, rowCount, getQueries());
  const assets = assetsResp?.list;

  useEffect(() => {
    if (defaultSelected?.length) {
      const selectedRows: number[] = [];
      assetsResp?.list.forEach((a: Asset, i: number) => {
        if (a.cli && defaultSelected.includes(a.cli)) {
          selectedRows.push(i);
        }
      });
      setSelectedRows(selectedRows);
    }
  }, [assetsResp, defaultSelected]);

  useEffect(() => {
    const computeSelected = (): SelectedAssets => {
      const selected = selectedRows.reduce((acc, r) => {
        if (assets?.[r]) acc.push(assets[r]);
        return acc;
      }, []);
      return {
        filters: selectAll ? getQueries() : undefined,
        assets: !selectAll ? selected : undefined,
      };
    };
    setSelectedAssets(computeSelected());
  }, [selectedRows, getQueries, assets, setSelectedAssets, selectAll]);

  const handleTableChange = (action: string, state: any) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      case "rowSelectionChange":
        setSelectAll(false);
        // @hack - force to bottom of execution que
        setTimeout(() => {
          setSelectedRows(state.selectedRows.data.map((r: any) => r.index));
        });
        break;
    }
  };

  const parsed = useMemo(
    () =>
      assets?.map((item: Asset) => {
        return [item.cli, item.tag, item.accountCode, item.costCentre, item.productType];
      }) || [],
    [assets],
  );

  useEffect(() => {
    if (selectAll) {
      setSelectedRows(parsed.map((_: any, i: number) => i));
    } else {
      setSelectedRows([]);
    }
  }, [selectAll, parsed]);
  return (
    <UIGrid size={{ md: 12 }}>
      <UIGrid>
        <FiltersCard
          className={classes.filters}
          fetchHook={useAllFilters}
          hasReset={false}
          hasDownload={false}
          usage={FILTERS_KEY}
        />
      </UIGrid>
      {isFetching ? (
        <div className={classes.loader}>
          <UILoader />
        </div>
      ) : (
        <>
          <SelectAllHeader
            onChange={() => setSelectAll(!selectAll)}
            disabled={isFetching}
            value={selectAll}
            selectedRows={selectedRows || []}
          />
          <div data-cy="asset-selection-table" className={classes.tableCtr}>
            <UIDataTable
              title=""
              data={parsed}
              columns={["CLI/Identifier", "Tag", "Account", "Cost Centre", "Product Type"]}
              options={{
                page,
                rowsPerPage: rowCount,
                rowsPerPageOptions: rowCountOptions,
                count: assetsResp?.total,
                onTableChange: handleTableChange,
                rowsSelected: selectedRows,
                pagination: true,
                download: false,
                serverSide: true,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                viewColumns: false,
                sort: false,
                search: false,
                rowHover: true,
                selectableRowsHeader: false,
                setTableProps: () => ({
                  size: "small",
                }),
                fixedHeader: false,
              }}
            />
          </div>
        </>
      )}
    </UIGrid>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  tableCtr: {
    marginTop: 0.5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    "& div:first-of-type": {
      marginTop: 1,
    },
  },
  augmentedTh: {
    padding: `6px ${theme.spacing(3)} 6px ${theme.spacing(2)}`,
    textAlign: "left",
  },
  selectAll: {
    position: "absolute",
    left: theme.spacing(2),
    top: 3,
    zIndex: 120,
  },
  filters: {
    paddingTop: 1,
  },
}));

export default AlertAssetSelection;
