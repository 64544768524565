import { PaginatedResponse } from "./../_app/api/types";
import { Schemas } from "../_app/api/types";
import { Modify } from "../_app/utils/types";
import { ParamsWithQueryFilters } from "../filter/types";

export enum RequestStatus {
  PENDING_CREATE = "PENDING_CREATE",
  PENDING_UPDATE = "PENDING_UPDATE",
  PENDING_DELETE = "PENDING_DELETE",
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
}

export type UsageAlert = Modify<
  Schemas["BillingAlertDetailDto"],
  {
    addBar: BarAlertAction;
    criteria?: AlertCriteria[];
    alertAction?: AlertAction;
    requestStatus?: RequestStatus;
  }
>;

export type CreateUsageAlert = Modify<
  ParamsWithQueryFilters<Schemas["BillingAlertCreateDto"]>,
  { criteria?: Schemas["CriterionDto"][] }
>;

export type UpdateUsageAlert = Schemas["BillingAlertUpdateDto"];

export type BillingAlertBulkEdit = Modify<
  Schemas["BillingAlertBulkUpdateRequest"],
  {
    criterion?: AlertCriteria;
    bar?: BarAlertAction;
  }
>;

export interface GetUsageAlertsResponse {
  list: UsageAlert[];
  nextPageToken: string;
}

export type UsageAlertHistory = Modify<
  Schemas["BillingAlertBreach"],
  {
    criteria?: AlertCriteria[];
    alertAction?: AlertAction;
  }
>;

export type AlertHistoryRecipient = Schemas["Recipient"];

export type GetUsageAlertsHistoryResponse = Modify<
  Schemas["PageContainerWithTokenBillingAlertBreach"],
  { list: UsageAlertHistory[] }
>;

export type AlertContact = Schemas["NotifyContactDefinitionDto"];
export type AlertAction = Modify<Schemas["AlertActionDto"], { bar?: BarAlertAction }>;
export type AlertActionUpdate = Schemas["AlertActionDto"];

export interface BarAlertAction {
  name: Schemas["AlertActionDto"]["bar"];
  value?: Schemas["AlertActionDto"]["bar"];
}

export interface AlertMeasure {
  value?: Schemas["CriterionDto"]["measure"];
  name: Schemas["CriterionDto"]["measure"];
  assetType: string;
  unit: string;
}

export type AlertCriteria = Modify<Schemas["CriterionDto"], { measure: AlertMeasure }>;

export type AlertCriteriaUpdate = Schemas["CriterionDto"];

export type ProductType = Schemas["ProductTypeDto"];

export type BundleAlertAccount = Schemas["AccountBundleAlertDTO"];

export type BundleAlertIndividual = Schemas["CliBundleAlertDTO"];

export type BundleAlertUpdate = Schemas["BundleAlertPairDTO"];

export type GetBundleAlertsAccountResponse = PaginatedResponse<BundleAlertAccount>;

export type GetBundleAlertsIndividualResponse = PaginatedResponse<BundleAlertIndividual>;
