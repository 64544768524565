import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { useFeedbackAlerts } from "../_app/hooks";
import { genericError } from "../_app/utils/text";
import { createTicket, createTicketComment, getTicketById, getTicketComments, getTickets } from "./api";
import { CreateTicket, GetTicketCommentsResponse, GetTicketsResponse, Ticket } from "./types";
import { useIdentityUser } from "../auth/hooks";
import { getFullName } from "../user/utils";

export const TICKETS_QUERY_KEY = "tickets";
export const TICKET_QUERY_KEY = "ticket";
export const TICKET_COMMENTS_QUERY_KEY = "ticketComments";

export function useTickets(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  return useQuery<GetTicketsResponse, AjaxResponse>([TICKETS_QUERY_KEY, { page, limit }], () => getTickets(page, limit), {
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    ...options,
  });
}

export function useSingleTicket(id: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  return useQuery<Ticket, AjaxResponse>([TICKET_QUERY_KEY, { id }], () => getTicketById(id), {
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    ...options,
  });
}

export function useTicketComments(ticketId: string, limit: number = 25, options = {}) {
  return useInfiniteQuery<GetTicketCommentsResponse, AjaxResponse>(
    [TICKET_COMMENTS_QUERY_KEY, { ticketId }],
    ({ pageParam = 0 }) => getTicketComments({ ticketId, page: pageParam, pageSize: limit }),
    {
      getNextPageParam: ({ page, pageSize, total }: GetTicketCommentsResponse) => {
        const end = typeof total === "number" ? total : 100;
        if ((page + 1) * pageSize < end) {
          return page + 1;
        } else {
          return undefined;
        }
      },
      ...options,
    },
  );
}

export function useCreateTicket(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Ticket, AjaxResponse, CreateTicket>(createTicket, {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([TICKETS_QUERY_KEY]);
      options?.onSuccess?.();
      setFeedbackAlertSuccess("Ticket created");
    },
    onError: () => {
      setFeedbackAlertError(genericError());
    },
  });
}

export function useCreateTicketComment(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const { data: identityUser } = useIdentityUser();

  return useMutation<any, any, any>(({ ticketId, comment }) => createTicketComment(ticketId, comment), {
    onMutate: async ({ ticketId, comment }) => {
      const queryKey = [TICKET_COMMENTS_QUERY_KEY, { ticketId }];
      await queryClient.cancelQueries(queryKey);
      const rollbackState = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old: any) => {
        const temp = {
          id: String(Date.now()),
          created: null,
          createdBy: getFullName(identityUser?.profile),
          internal: true,
          comment,
        };
        if (!old) return { pages: [{ page: 0, pageSize: 25, list: [temp] }] };
        const oldPage1 = old.pages[0];
        const newPage1 = {
          ...oldPage1,
          list: [temp, ...oldPage1.list],
        };
        return {
          ...old,
          pages: [newPage1, ...old.pages.slice(1)],
        };
      });
      return { rollbackState };
    },
    onError: (error, { ticketId }, context: { rollbackState: unknown }) => {
      const queryKey = [TICKET_COMMENTS_QUERY_KEY, { ticketId }];
      if (context?.rollbackState) queryClient.setQueryData(queryKey, context?.rollbackState);
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    onSuccess: (data, { ticketId }) => {
      const queryKey = [TICKET_COMMENTS_QUERY_KEY, { ticketId }];
      if (ticketId) {
        queryClient.invalidateQueries(queryKey);
      }
      setFeedbackAlertSuccess("Comment added");
    },
    ...options,
  });
}
