import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import Main from "../../_app/layouts/Main";
import { UIAlert, UIButton, UIConfirm, UIGrid, UILoader } from "../../_app/components";
import SelectAllHeader from "../../_app/components/Table/SelectAllHeader";
import { rowParser } from "../../_app/components/Table/helpers";
import ManageCostCentresTabBar from "../components/ManageCostCentresTabBar";
import FiltersCard from "../../filter/components/FiltersCard";
import SelectDropdown from "../../form/components/SelectDropdown";
import { useCostCentres, useCostCentresAssign, useCostCentresFilters } from "../hooks";
import { useFilterQueries } from "../../filter/hooks";
import { useLiveAssets } from "../../asset/hooks";
import { permissionCodes } from "../../permission/hooks";
import { Asset } from "../../asset/types";
import { formatCostCentreCode, formatCostCentreOptions } from "../utils";
import { createStylesheet } from "../../_app/utils/styles";
import UIDataTable from "../../_app/components/Table/UIDataTable";
import { Feature } from "../../feature/types";

const COST_CENTRES_FILTERS: string = "COST_CENTRES";

export const UpdateCostCentres = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [canView, setCanView] = useState(false);
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedCcId, setSelectedCcId] = useState<null | string>(null);
  const [confOpen, setConfOpen] = useState(false);
  const queries = useFilterQueries();

  const { data: costCentresResp, isFetching: isFetchingCc } = useCostCentres();
  const { data: assetsResp, isFetching } = useLiveAssets(page, rowCount, queries, {
    enabled: canView,
  });
  const assets = assetsResp?.list;
  const selectedAssets = selectedRows.reduce((acc, r) => {
    if (assets?.[r]) acc.push(assets?.[r]?.id);
    return acc;
  }, []);

  const { mutate: executeAssignCostCentres, isLoading: isAssigning } = useCostCentresAssign(
    { page, limit: rowCount, queries },
    { onSuccess: () => selectedAssets.forEach((assetId: string) => queryClient.invalidateQueries(["asset", assetId])) },
  );

  const handleTableChange = (action: string, state: any) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      case "rowSelectionChange":
        setSelectAll(false);
        // @hack - force to bottom of execution que
        setTimeout(() => {
          setSelectedRows(state.selectedRows.data?.map((r: any) => r?.index));
        });
        break;
    }
  };

  const parsed = useMemo(
    () =>
      assets?.map((item: Asset) => {
        return rowParser([item?.cli, item?.accountName, item?.tag, formatCostCentreCode(item?.costCentre)]);
      }) || [],
    [assets],
  );

  const options = useMemo(() => formatCostCentreOptions(costCentresResp ?? []), [costCentresResp]);

  useEffect(() => {
    if (selectAll) {
      setSelectedRows(parsed?.map((_, i) => i));
    } else {
      setSelectedRows([]);
    }
  }, [selectAll, parsed]);

  const canAssign = Boolean(selectedCcId && selectedRows.length);

  const onAssign = () => {
    if (!canAssign || isAssigning) return;
    executeAssignCostCentres({
      filters: selectAll ? queries : undefined,
      assetIds: !selectAll ? selectedAssets : undefined,
      costCentreIds: [selectedCcId],
    });
    setSelectAll(false);
    setSelectedRows([]);
    setSelectedCcId(null);
  };

  useEffect(
    function handleFilterChange() {
      setPage(0);
    },
    [queries],
  );

  return (
    <Main
      title="Update Cost Centres"
      data-cy="bulk-update-cost-centres-page"
      needSelectedAccount
      feature={Feature.CostCentreManagement}
      accessPermission={permissionCodes.COST_CENTRE_MANAGE}
      accessGrantedCb={() => setCanView(true)}
    >
      <ManageCostCentresTabBar selectedTab="update-cost-centres">
        <UIAlert severity="info" className={classes.info}>
          Cost centres allow you to organise and group your charges. A report providing a breakdown of you charges for cost centre
          costing purposes can be downloaded from the Bill Explorer every month.
        </UIAlert>
        <UIGrid>
          <FiltersCard
            fetchHook={useCostCentresFilters}
            title="Filter"
            hasReset={true}
            hasDownload={false}
            usage={COST_CENTRES_FILTERS}
          />
        </UIGrid>
        {isFetching || isFetchingCc || isAssigning ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>
            <UIAlert severity="info" className={classes.alert}>
              Select asset(s) then select the cost centre you wish to apply. You can create or edit cost centres on the Manage tab
              above.
            </UIAlert>
            <SelectAllHeader
              onChange={() => setSelectAll(!selectAll)}
              disabled={isFetching}
              value={selectAll}
              selectedRows={selectedRows || []}
            >
              <SelectDropdown
                ctrClass={classes.dropdown}
                data={options}
                name="costCentreSelection"
                label={"Select Cost Centre"}
                onChange={(e: any) => setSelectedCcId(e.target.value)}
              />
              <UIButton
                variant="contained"
                color="primary"
                disabled={!canAssign}
                onClick={() => setConfOpen(true)}
                data-cy="apply-button"
              >
                Apply
              </UIButton>
            </SelectAllHeader>
            <div data-cy="cost-centre-selection-table" className={classes.tableCtr}>
              <UIDataTable
                title=""
                data={parsed}
                columns={["CLI/Identifier", "Account", "Tag", "Cost Centre"]}
                options={{
                  page,
                  rowsPerPage: rowCount,
                  count: assetsResp?.total,
                  rowsPerPageOptions: rowCountOptions,
                  onTableChange: handleTableChange,
                  rowsSelected: selectedRows,
                  pagination: true,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  viewColumns: false,
                  sort: false,
                  search: false,
                  rowHover: true,
                  serverSide: true,
                  selectableRowsHeader: false,
                  setTableProps: () => ({ size: "small" }),
                  setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                }}
              />
              <UIConfirm
                title="Apply Change to Cost Centre"
                onSubmitTitle="Apply"
                setOpen={setConfOpen}
                open={confOpen}
                onConfirm={onAssign}
              >
                Are you sure you want to apply this change to the cost centre? It will be applied with immediate effect.
              </UIConfirm>
            </div>
          </>
        )}
      </ManageCostCentresTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  tableCtr: {
    marginTop: 0.5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    "& div:first-of-type": {
      marginTop: 0,
    },
  },
  dropdown: {
    width: "20%",
    minWidth: 170,
    marginTop: 4,
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "100%",
      },
    },
  },
  alert: {
    width: "fit-content",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  info: {
    alignItems: "center",
    width: "fit-content",
    marginBottom: theme.spacing(3),
  },
}));

export default UpdateCostCentres;
