import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import SelectDropdown, { Option } from "../../form/components/SelectDropdown";
import { useAccountsForLevel } from "../../user/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIDialog, UIDialogActions, UIDialogContent, UIDialogTitle, UITypography } from "../../_app/components";
import { AccountLevel } from "../../account/utils";
import { getAccountOption } from "../../user/utils";
import { deleteLogicMonitorToken } from "../../logic-monitor/api";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeedbackAlerts } from "../../_app/hooks";
import { useUserAccessibleAccounts } from "../../account/hooks";
import { AccountLevels } from "../../account/types";

interface SwitchAccountsDialogProps {
  title: string;
  open: boolean;
  setOpen: Function;
}

const PAGES_TO_REDIRECT = ["/usage-analysis", "/service-analysis"];

const SwitchAccountsDialog = ({ title, open, setOpen }: SwitchAccountsDialogProps) => {
  const classes = useStyles();
  const { refreshAccountHierarchy, getAccountByLevel, contextId } = useAccountContext();
  const accessibleAccounts = useUserAccessibleAccounts();
  const navigate = useNavigate();
  const location = useLocation();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const [accountSearchTerm, setAccountSearchTerm] = useState<string | undefined>();
  const [subAccountSearchTerm, setSubAccountSearchTerm] = useState<string | undefined>();

  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>();
  const [selectedSubAccountId, setSelectedSubAccountId] = useState<string | undefined>();

  const {
    data: accounts,
    fetchNextPage: fetchAccountNextPage,
    hasNextPage: hasAccountNextPage,
    isFetching: isAccountListFetching,
  } = useAccountsForLevel(`${AccountLevel.Account.id}`, { "search-term": accountSearchTerm });

  const {
    data: subAccounts,
    fetchNextPage: fetchSubAccountNextPage,
    hasNextPage: hasSubAccountNextPage,
    isFetching: isSubAccountListFetching,
  } = useAccountsForLevel(`${AccountLevel.SubAccount.id}`, {
    "search-term": subAccountSearchTerm,
    parent: selectedAccountId,
  });

  const reset = useCallback(() => {
    setSelectedAccountId(undefined);
    setSelectedSubAccountId(undefined);
    setAccountSearchTerm(undefined);
    setSubAccountSearchTerm(undefined);
  }, []);

  const accountsList = useMemo(() => {
    return accounts?.pages.flatMap((page) => page.list.map((account: any) => getAccountOption(account))) || [];
  }, [accounts]);

  const subAccountsList = useMemo(() => {
    return subAccounts?.pages.flatMap((page) => page.list.map((account: any) => getAccountOption(account))) || [];
  }, [subAccounts]);

  const showAccountsSelector = accessibleAccounts.some((account) => account.level.flag === "HEAD");
  const showSubAccountsSelector = accessibleAccounts.some((account) => account.level.flag === "SUB");
  const hasSingleHeadAccount = useMemo(() => {
    return accessibleAccounts.filter((account) => account.level.flag === "HEAD").length <= 1;
  }, [accessibleAccounts]);

  const isAccountSelected = selectedSubAccountId || selectedAccountId;

  const disableSearch = useCallback((options: Option[], searchTerm: string | undefined) => {
    return options.length < 5 && (searchTerm === undefined || searchTerm === "");
  }, []);

  useEffect(() => {
    if (open) {
      reset();
      if (hasSingleHeadAccount) {
        setSelectedAccountId(getAccountByLevel(AccountLevels.Account)?.id);
      }
    }
  }, [open, hasSingleHeadAccount, reset, getAccountByLevel]);

  const switchContext = useCallback(() => {
    const id = selectedSubAccountId || selectedAccountId;
    if (id) refreshAccountHierarchy(id);
  }, [selectedSubAccountId, selectedAccountId, refreshAccountHierarchy]);

  const handleSwitch = useCallback(() => {
    setFeedbackAlertSuccess("Switch successful");
    // if switching context on /usage-analysis or /service-analysis, redirect to bills to choose correct bill period
    if (PAGES_TO_REDIRECT.includes(location.pathname)) {
      navigate("/bills");
    }
    deleteLogicMonitorToken();
    switchContext();
    setOpen(false);
  }, [location.pathname, navigate, setFeedbackAlertSuccess, switchContext]);

  return (
    <UIDialog open={open} onClose={() => setOpen(false)} data-cy="switch-accounts-dialog">
      <UIDialogTitle className={classes.dialogTitle}>{title}</UIDialogTitle>
      <UIDialogContent className={classes.dialogContent}>
        <div className={classes.resetBar}>
          <UITypography>Select the level you wish to view</UITypography>
          <UIButton variant="outlined" color="primary" onClick={reset} data-cy="reset-button">
            Reset
          </UIButton>
        </div>
        {showAccountsSelector && (
          <SelectDropdown
            label="Account"
            data-cy="account-select"
            data={accountsList}
            value={selectedAccountId ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSelectedAccountId(event.target.value);
            }}
            searchOn={(q: any) => {
              setSelectedSubAccountId(undefined);
              setAccountSearchTerm(q || undefined);
            }}
            ctrClass={classes.textField}
            nextPage={fetchAccountNextPage}
            hasNextPage={hasAccountNextPage}
            isFetching={isAccountListFetching}
            infiniteScroll
            hasSearch={!disableSearch(accountsList, accountSearchTerm)}
            hasNoOptionsText
          />
        )}
        {isAccountSelected && showSubAccountsSelector && (
          <SelectDropdown
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSelectedSubAccountId(event.target.value);
            }}
            value={selectedSubAccountId ?? ""}
            label="Sub Account"
            data={subAccountsList}
            searchOn={(q: any) => {
              setSubAccountSearchTerm(q || undefined);
            }}
            ctrClass={classes.textField}
            nextPage={fetchSubAccountNextPage}
            hasNextPage={hasSubAccountNextPage}
            isFetching={isSubAccountListFetching}
            infiniteScroll
            hasSearch={!disableSearch(subAccountsList, subAccountSearchTerm)}
            hasNoOptionsText
          />
        )}
      </UIDialogContent>
      <UIDialogActions className={classes.dialogActions}>
        <UIButton
          onClick={handleSwitch}
          variant="contained"
          color="primary"
          fullWidth
          data-cy="switch-account-button"
          disabled={!isAccountSelected}
        >
          Switch
        </UIButton>
      </UIDialogActions>
    </UIDialog>
  );
};

const useStyles = createStylesheet((theme) => ({
  dialogTitle: {
    textAlign: "center",
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.tertiary.contrastText,
  },
  dialogContent: {
    width: "600px",
    padding: theme.spacing(6),
    paddingBottom: theme.spacing(0),
  },
  dialogActions: {
    padding: theme.spacing(0),
    margin: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  resetBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2rem",
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
}));

export default SwitchAccountsDialog;
