import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UIButton, UICardActions, UITextField } from "../../_app/components";
import UIAttrValue from "../../_app/components/UIAttrValue";
import UIRichTextEditor from "../../_app/components/UIRichTextEditor";
import Main from "../../_app/layouts/Main";
import { createStylesheet } from "../../_app/utils/styles";
import { useClisInfinite } from "../../asset/hooks";
import { useContactsInfinite } from "../../contacts/hooks";
import { Contact } from "../../contacts/types";
import { Feature } from "../../feature/types";
import Autocomplete from "../../form/components/Autocomplete";
import SelectDropdown from "../../form/components/SelectDropdown";
import { useValidation } from "../../form/hooks";
import { permissionCodes } from "../../permission/hooks";
import { getFullName } from "../../user/utils";
import { useCreateTicket } from "../hooks";
import { CreateTicket } from "../types";

export const AddNewTicket = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const deeplinkDefaults = location.state?.payload || {};
  const [cliQuery, setCliQuery] = useState(deeplinkDefaults?.cli);

  const initialState: CreateTicket = {
    requestType: "General Enquiry",
    subject: "General Enquiry - ",
    contact: "",
    productType: undefined,
    description: undefined,
    cli: undefined,
    ref: undefined,
    ...deeplinkDefaults,
  };
  const [fields, setFields] = useState(initialState as CreateTicket);

  const { data: contacts, fetchNextPage: fetchContactsNextPage, hasNextPage: hasContactsNextPage } = useContactsInfinite();

  const {
    data: clis,
    fetchNextPage: fetchClisNextPage,
    hasNextPage: hasClisNextPage,
    isFetching: isFetchingClis,
  } = useClisInfinite(cliQuery);

  const { mutate: executeCreate, isLoading: isCreating } = useCreateTicket({
    onSuccess: () => {
      reset();
      navigate(-1);
    },
  });

  const options = {
    product_types: [
      { label: "Mobile", value: "Mobile" },
      { label: "WLR", value: "WLR" },
      { label: "Broadband", value: "Broadband" },
      { label: "Data & Hosting", value: "Data & Hosting" },
      { label: "Daisy Hosted Voice", value: "Daisy Hosted Voice" },
      { label: "Maintenance & System", value: "Maintenance & System" },
      { label: "Leased Line", value: "Leased Line" },
    ],
    request_types: [
      { label: "General Enquiry", value: "General Enquiry" },
      { label: "Billing Query", value: "Billing Query" },
      { label: "Fault", value: "Fault" },
    ],
    contacts:
      contacts?.pages.reduce(
        (acc: any, page: any) => [
          ...acc,
          ...page.list?.map((c: Contact) => ({
            id: c.id,
            label: getFullName(c),
            value: c.id,
            meta: c,
          })),
        ],
        [],
      ) || [],
    clis:
      clis?.pages.reduce(
        (acc: any, page: any) => [
          ...acc,
          ...page?.map((cli: string) => ({
            id: cli,
            label: cli,
            value: cli,
          })),
        ],
        [],
      ) || [],
  };
  const { validationErrors, validateSingle, validateAll } = useValidation(
    {
      requestType: { required: true },
      subject: { required: true, minLength: 2 },
      productType: { required: true },
      cli: { required: true },
      description: { required: true },
      contact: { required: true },
    },
    fields,
  );
  const updateFields = (updates: any) => setFields({ ...fields, ...updates });
  const handleChange = (field: string) => (event: any) => {
    const value = event?.target?.value || event?.value || event || "";
    const val = typeof value === "string" ? value : "";
    validateSingle(field, val);
    updateFields({ [field]: val });
  };

  const reset = () => setFields(initialState);

  const canSubmit = () => {
    const errors = Object.values(validationErrors).filter((e) => Boolean(e));
    return errors.length <= 0;
  };

  const handleSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      executeCreate(fields);
    }
  };

  const selectedContact = options.contacts.find((c) => c.id === fields.contact);

  return (
    <Main
      title="Create New Ticket"
      data-cy="add-ticket-page"
      feature={Feature.DclSupport}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      isModal
    >
      <div className={classes.ctr}>
        <Autocomplete
          label="Select Request Type"
          loading={false}
          name="requestType"
          onSearch={() => null}
          onSelect={handleChange("requestType")}
          options={options.request_types}
          value={options.request_types.find((type: any) => type.value === fields.requestType) || null}
          valueFrom="value"
          textFrom="label"
          required
          error={Boolean(validationErrors.requestType)}
          helperText={validationErrors.requestType}
        />
        <UITextField
          fullWidth
          label="Subject"
          name="subject"
          type="text"
          onChange={handleChange("subject")}
          required
          value={fields?.subject}
          variant="outlined"
          size="small"
          error={Boolean(validationErrors.subject)}
          helperText={validationErrors.subject}
        />

        <Autocomplete
          label="Select Product *"
          loading={false}
          name="productType"
          onSearch={() => null}
          onSelect={handleChange("productType")}
          options={options.product_types}
          value={options.product_types.find((product: any) => product.value === fields.productType) || null}
          valueFrom="value"
          textFrom="label"
          error={Boolean(validationErrors.productType)}
          helperText={validationErrors.productType}
        />
        <SelectDropdown
          name="cli"
          label="CLI/Identifier *"
          value={fields.cli || ""}
          data={options.clis}
          infiniteScroll={true}
          multiple={false}
          multiline
          hasSearch
          nextPage={fetchClisNextPage}
          hasNextPage={hasClisNextPage}
          onChange={handleChange("cli")}
          defaultSearch={cliQuery}
          searchOn={(q: string) => setCliQuery(q)}
          error={Boolean(validationErrors.cli)}
          helperText={validationErrors.cli}
          resetOnClose={false}
          isFetching={isFetchingClis}
        />
        <UIRichTextEditor
          onChange={handleChange("description")}
          placeholder="Description *"
          value={fields.description || ""}
          error={Boolean(validationErrors.description)}
          helperText={validationErrors.description}
        />
        <SelectDropdown
          name="contact"
          label="Contact *"
          value={fields.contact || ""}
          data={options.contacts}
          infiniteScroll={true}
          multiple={false}
          nextPage={fetchContactsNextPage}
          hasNextPage={hasContactsNextPage}
          onChange={handleChange("contact")}
          error={Boolean(validationErrors.contact)}
          helperText={validationErrors.contact}
        />
        {Boolean(selectedContact?.meta.email || selectedContact?.meta.mobileNumber) && (
          <div className={classes.contactMeta}>
            {Boolean(selectedContact?.meta.email) && <UIAttrValue title="Email Address" value={selectedContact?.meta.email} />}
            {Boolean(selectedContact?.meta.mobileNumber) && (
              <UIAttrValue title="Mobile Number" value={selectedContact?.meta.mobileNumber} valueProps={{ align: "right" }} />
            )}
          </div>
        )}
        <UITextField
          fullWidth
          label="Your Reference"
          name="ref"
          type="text"
          onChange={handleChange("ref")}
          value={fields.ref || ""}
          variant="outlined"
          size="small"
        />
        <UICardActions className={classes.actions}>
          <UIButton
            color="primary"
            disabled={!canSubmit()}
            size="medium"
            type="submit"
            variant="contained"
            data-cy="create-btn"
            isLoading={isCreating}
            onClick={handleSubmit}
          >
            Create
          </UIButton>
          <UIButton color="primary" size="medium" variant="text" onClick={() => navigate(-1)} data-cy="cancel-btn">
            Cancel
          </UIButton>
        </UICardActions>
      </div>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    width: "100%",
    minHeight: "calc(100vh - 136px)",
    paddingTop: 22,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  selectedType: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  actions: {
    padding: "0px",
    flex: 1,
    alignItems: "flex-end",
    paddingBottom: theme.spacing(3),
  },
  contactMeta: {
    paddingLeft: 14,
    paddingRight: 14,
    borderRadius: 5,
    border: "1px solid rgba(225,225,225,1)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > div": {
      marginBottom: 5,
      marginTop: 5,
    },
  },
}));

export default AddNewTicket;
