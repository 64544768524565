import { useLocation } from "react-router-dom";
import StatusMessage from "../screens/StatusMessage";
import AuthLayout from "../layouts/AuthLayout";

const AuthFeedback = () => {
  const location = useLocation();
  const { title, message, type } = location.state;
  return (
    <AuthLayout title={title}>
      <StatusMessage type={type} message={message} />
    </AuthLayout>
  );
};

export default AuthFeedback;
