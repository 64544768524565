import { useState } from "react";
import CognitoHeader from "../components/cognito/CognitoHeader";
import CognitoFooter from "../components/cognito/CognitoFooter";
import { useQueryParam } from "../../_app/hooks";
import { ChangePasswordForm } from "../types";
import { confirmResetPassword } from "aws-amplify/auth";
import AuthLayout from "../layouts/AuthLayout";
import StatusMessage from "./StatusMessage";
import CognitoPasswordChangeForm from "../components/cognito/CognitoPasswordChangeForm";
import { UIGrid } from "../../_app/components";
import { useCognitoUserpoolPolicy } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import { useAuthenticator } from "@aws-amplify/ui-react";

type ForgotPasswordState = {
  type: "success" | "error";
  message: string;
};

const ForgotPassword = () => {
  const [forgotPasswordState, setForgotPasswordState] = useState<ForgotPasswordState | null>(null);
  const username = useQueryParam("username") as string;
  const code = useQueryParam("code") as string;
  const { data: passwordPolicy, isFetching } = useCognitoUserpoolPolicy();
  const { signOut } = useAuthenticator((context) => [context.user]);

  async function handleChangePassword(form: ChangePasswordForm) {
    signOut();
    try {
      await confirmResetPassword({
        username: username,
        newPassword: form.password,
        confirmationCode: code,
      });
      setForgotPasswordState({
        type: "success",
        message: "Your password has been change. You can now login with your new password.",
      });
    } catch (error) {
      if (error instanceof Error && error.name == "ExpiredCodeException") {
        setForgotPasswordState({
          type: "error",
          message: "It looks like the password reset link has expired. Please request a new link to reset your password.",
        });
      } else {
        setForgotPasswordState({
          type: "error",
          message: "We couldn't complete the password change. Please try again, or reach out to support if the issue continues.",
        });
      }
    }
  }

  if (isFetching) return <UILoader />;

  return (
    <AuthLayout title="Reset Password">
      {forgotPasswordState !== null ? (
        <StatusMessage type={forgotPasswordState.type} message={forgotPasswordState.message} />
      ) : (
        <UIGrid width="480px">
          <CognitoHeader />
          <CognitoPasswordChangeForm heading="Reset password" passwordPolicy={passwordPolicy} onSubmit={handleChangePassword} />
          <CognitoFooter />
        </UIGrid>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
