import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { useFeedbackAlerts } from "../_app/hooks";
import { genericError } from "../_app/utils/text";
import {
  assignCostCentres,
  createCostCentre,
  deleteCostCentre,
  getAccountHierarchy,
  getAccounts,
  getBillingAddress,
  getBillSettings,
  getCostCentres,
  getFilteredCostCentres,
  getMyAccounts,
  getMyCostCentres,
  updateBillingAddress,
  updateBillSettings,
  updateCostCentre,
} from "./api";
import { costCentreFilters } from "./filterConfig";
import {
  AccessibleAccountDto,
  AccountHierarchy,
  BillingAddress,
  BillingAddressUpdateParams,
  BillSettings,
  CostCentre,
  CostCentreAssignParams,
  CostCentreWithAccount,
  FilterCostCentresResponse,
  GetAccountsParams,
  GetAccountsResponse,
  Group,
} from "./types";
import { AccountLevel } from "./utils";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";

export function useUserAccessibleAccounts(): AccessibleAccountDto[] {
  const { data: groups } = useMyAccounts();
  const { groupId } = useAccountContext();

  return groups?.find((group) => group.id === groupId)?.userAccessibleAccounts ?? [];
}

export function useBillingAddress(options = {}) {
  const { selectedAccount } = useAccountContext();
  const accountId = selectedAccount?.id;

  return useQuery<BillingAddress, AjaxResponse>(["billingAddress", accountId], () => getBillingAddress(accountId), {
    enabled: !!accountId,
    ...options,
  });
}

export function useBillingAddressUpdate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertSuccess, setFeedbackAlertError } = useFeedbackAlerts();
  const { selectedAccount } = useAccountContext();
  const accountId = selectedAccount?.id;

  return useMutation<BillingAddress, AjaxResponse, BillingAddressUpdateParams>(
    (address) => updateBillingAddress(address, accountId),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["billingAddress"], () => data);
        setFeedbackAlertSuccess("Billing address changed.");
      },
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message || genericError());
      },
      ...options,
    },
  );
}

export function useBillSettings(options = {}) {
  const { selectedAccount } = useAccountContext();
  const accountId = selectedAccount?.id;

  return useQuery<BillSettings, AjaxResponse>(["billSettings"], () => getBillSettings(accountId), {
    enabled: !!accountId,
    ...options,
  });
}

export function useBillSettingsUpdate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const { selectedAccount } = useAccountContext();
  const accountId = selectedAccount?.id;

  return useMutation<BillSettings, AjaxResponse, BillSettings>(
    (billSettings: BillSettings) => updateBillSettings(billSettings, accountId),
    {
      onSuccess: (response, body: BillSettings) => {
        queryClient.setQueryData(["billSettings"], () => body);
      },
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message || genericError());
      },
      ...options,
    },
  );
}

export function usePaperBillCharge() {
  const unit = "£";
  const value = "7.50";
  const parsed = `${unit}${value}`;
  return { unit, value, parsed };
}

export function useMyAccounts(options = {}) {
  return useQuery<Group[], AjaxResponse>(["myAccounts"], getMyAccounts, {
    ...options,
  });
}

export function useAccountsForParent(params: GetAccountsParams, options = {}) {
  return useInfiniteQuery<GetAccountsResponse>(
    ["accountsForParent", JSON?.stringify(params || {})],
    ({ pageParam = 0 }) => {
      return getAccounts({
        tree: true,
        offset: pageParam,
        ...params,
      });
    },
    {
      getNextPageParam: ({ page, pageSize }: any) => (page + 1) * pageSize,
      ...options,
    },
  );
}

export function useAccountHierarchy(accountId: string | undefined, options = {}) {
  return useQuery<any, AjaxResponse, AccountHierarchy>(["accountHierarchy", accountId], () => getAccountHierarchy(accountId), {
    select: (data) => ({
      level: AccountLevel.fromString(data.level)!,
      hierarchy: data.hierarchy,
    }),
    ...options,
  });
}

/* COST CENTRES START */

export function useCostCentres(options = {}) {
  return useQuery<CostCentre[], AjaxResponse>(["costCentres"], getCostCentres, {
    ...options,
  });
}

export function useCostCentresMy(options = {}) {
  return useQuery<CostCentreWithAccount, AjaxResponse>(["costCentres"], getMyCostCentres, {
    ...options,
  });
}

export function useFilteredCostCentres(search: string, options = {}) {
  return useQuery<FilterCostCentresResponse, AjaxResponse>(
    ["filtered-cost-centres", search],
    () => getFilteredCostCentres({ search }),
    { ...options },
  );
}

export function useCostCentreUpdate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertSuccess, setFeedbackAlertError } = useFeedbackAlerts();

  return useMutation<CostCentre, AjaxResponse, CostCentre>((update: CostCentre) => updateCostCentre(update), {
    onSuccess: (updated: CostCentre) => {
      queryClient.setQueryData(["costCentres"], (list: any) => {
        const mutateOld = (old: any) => {
          old.forEach(function (cc: any, i: number) {
            if (cc.id === updated.id) {
              //@ts-ignore
              this[i] = updated;
            } else if (cc.subCostCentres.length) {
              mutateOld(cc.subCostCentres);
            }
          }, old);
        };
        mutateOld(list);
        return list;
      });
      setFeedbackAlertSuccess("Cost Centre updated.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useCostCentreCreate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertSuccess, setFeedbackAlertError } = useFeedbackAlerts();

  return useMutation<CostCentre, AjaxResponse, CostCentre>((cc: CostCentre) => createCostCentre(cc), {
    onSuccess: (updated: any) => {
      queryClient.setQueryData(["costCentres"], (list: any) => {
        const mutateOld = (old: any) => {
          if (updated.parent === null) {
            old.push(updated);
          } else {
            old.forEach(function (cc: any, i: number) {
              if (cc.id === updated.parent) {
                //@ts-ignore
                this[i].subCostCentres.push(updated);
              } else if (cc.subCostCentres.length) {
                mutateOld(cc.subCostCentres);
              }
            }, old);
          }
        };

        mutateOld(list);
        return list;
      });

      setFeedbackAlertSuccess("Cost Centre added");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useCostCentreDelete(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertSuccess, setFeedbackAlertError } = useFeedbackAlerts();

  return useMutation<string, AjaxResponse, string>((id: string) => deleteCostCentre(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["costCentres"]);
      queryClient.invalidateQueries(["assets"]);
      queryClient.invalidateQueries(["assets-live"]);
      setFeedbackAlertSuccess("Cost Centre deleted");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function useCostCentresAssign(
  params?: { page: number; limit: number; queries?: any[]; clis?: string[] },
  options: any = {},
  refetchCCs: boolean = true,
) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertSuccess, setFeedbackAlertError } = useFeedbackAlerts();

  return useMutation<CostCentreAssignParams, AjaxResponse, any>(assignCostCentres, {
    ...options,
    onSuccess: () => {
      if (typeof options?.onSuccess === "function") options?.onSuccess?.();
      if (refetchCCs) queryClient.invalidateQueries(["costCentres"]);
      queryClient.invalidateQueries(["assets-live"]);
      queryClient.invalidateQueries(["assets", params]);
      setFeedbackAlertSuccess("Cost centre updated.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
  });
}

export function useCostCentresFilters() {
  return { data: costCentreFilters };
}

/* COST CENTRES END */
