import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UIButton, UIGrid } from "../../_app/components";
import { Add as AddIcon } from "../../_app/components/icons";
import { rowParser } from "../../_app/components/Table/helpers";
import UIDataTable, { UIDataTableState } from "../../_app/components/Table/UIDataTable";
import UIHtml from "../../_app/components/UIHtml";
import Main from "../../_app/layouts/Main";
import { formatDate } from "../../_app/utils/format";
import { createStylesheet } from "../../_app/utils/styles";
import { Feature } from "../../feature/types";
import { permissionCodes } from "../../permission/hooks";
import { useTickets } from "../hooks";
import { Ticket } from "../types";

export const Tickets = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const canEdit = true;

  const { data: tickets, isFetching } = useTickets(page, rowCount);

  const handleAction = (action: string, ticket?: Ticket) => {
    if (!canEdit) return;
    switch (action) {
      case "add":
        navigate("/tickets/new");
        break;
      case "view":
      case "edit":
        navigate(`/tickets/details/${ticket?.id}`, {
          state: ticket,
        });
        break;
      default:
        break;
    }
  };

  const columns = ["Account", "Our Ref", "Your Ref", "Subject", "Description", "Created", "Resolved", "Status", ""];
  const parsed =
    tickets?.list?.map((t: Ticket) => {
      return rowParser([
        t.accountName,
        t.ourRef,
        t.yourRef,
        t.subject,
        <UIHtml html={t.description} />,
        formatDate(t.created, "dd/MM/yyyy HH:mm:ss"),
        formatDate(t.resolved, "dd/MM/yyyy HH:mm:ss"),
        t.status,
        <UIButton
          color="primary"
          variant="contained"
          size="small"
          className={classes.viewLink}
          onClick={() => handleAction("view", t)}
        >
          View
        </UIButton>,
      ]);
    }) || [];

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title={
        <>
          Tickets
          {canEdit && (
            <UIButton
              variant="contained"
              color="primary"
              data-cy="add-ticket-btn"
              startIcon={<AddIcon />}
              onClick={() => handleAction("add")}
            >
              <span className={classes.addBtnText}>Create New Ticket</span>
            </UIButton>
          )}
        </>
      }
      data-cy="tickets-page"
      isLoading={isFetching}
      feature={Feature.DclSupport}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
      needSelectedAccount={true}
    >
      <UIGrid className={classes.ctr}>
        <div className={classes.tableCtr} data-cy="tickets-table">
          <UIDataTable
            title=""
            data={parsed}
            columns={columns || []}
            options={{
              onTableChange: handleTableChange,
              pagination: true,
              page,
              rowsPerPage: rowCount,
              rowsPerPageOptions: rowCountOptions,
              count: tickets?.total,
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              sort: false,
              search: false,
              selectableRows: "none",
              serverSide: true,
              rowHover: true,
              setTableProps: () => ({ size: "small" }),
              viewColumns: false,
              setRowProps: (row) => {
                return {
                  "data-cy": `ticket-id-${row?.[2]}`,
                };
              },
            }}
          />
        </div>
      </UIGrid>
      <Outlet />
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    width: "100%",
    padding: "0",
    marginTop: theme.spacing(1.5),
  },
  tableCtr: {
    "& tr td": {
      maxWidth: "20vw",
      wordBreak: "break-word",
    },
    "& tr td:nth-of-type(6n)": {
      maxWidth: "130px",
      wordBreak: "break-all",
    },
    "& tr td:nth-of-type(7n)": {
      maxWidth: "130px",
      wordBreak: "break-all",
    },
    "& tr td:nth-of-type(8n)": {
      minWidth: "116px",
    },
  },
  addBtn: {
    position: "absolute",
    top: "95px",
    right: "35px",
  },
  addBtnText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  viewLink: {},
}));

export default Tickets;
