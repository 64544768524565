import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { UIAlert, UIButton, UIGrid, UILoader, UITabPanel } from "../../_app/components";
import { rowParser } from "../../_app/components/Table/helpers";
import SelectAllHeader from "../../_app/components/Table/SelectAllHeader";
import MobileManagementTabBar from "../components/MobileManagementTabBar";
import SelectDropdown from "../../form/components/SelectDropdown";
import { useMobileAssets, useMobileManagementFilters } from "../hooks";
import { useFilterQueries } from "../../filter/hooks";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { permissionCodes } from "../../permission/hooks";
import { useReset } from "../../_app/hooks";
import { IdValueQuery } from "../../_app/api";
import { capitalize } from "../../_app/utils/format";
import { formatCostCentreCode } from "../../account/utils";
import { MobileAsset } from "../types";
import { MobileLimits } from "../utils";
import { createStylesheet } from "../../_app/utils/styles";
import UIDataTable from "../../_app/components/Table/UIDataTable";
import { Feature } from "../../feature/types";
import { UserType } from "../../user-level/types";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import FiltersCard from "../../filter/components/FiltersCard";

type Action = {
  id: number;
  value: string;
  action: () => void;
  enabled: boolean;
};

export interface SelectedParams {
  assets?: MobileAsset[];
  asset: MobileAsset;
  filters?: IdValueQuery[];
  from?: string;
}

const MOBILE_NUMBERS_FILTERS: string = "MOBILE_NUMBERS";

export const MobileManagement = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [canView, setCanView] = useState(false);
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedAction, setSelectedAction] = useState<Action | undefined>(undefined);
  const [actionKey, resetAction] = useReset();
  const queries = useFilterQueries();
  const { userType } = useAccountContext();

  const { data: assetsResp, isFetching } = useMobileAssets(page, rowCount, queries, undefined, { enabled: canView });
  const assets = assetsResp?.content;

  const getSelectedParams = useCallback(
    (singleAsset = false): SelectedParams => {
      const selected = selectedRows.reduce((acc, r) => {
        if (assets?.[r]) acc.push(assets?.[r]);
        return acc;
      }, []);
      return {
        filters: selectAll ? queries : undefined,
        assets: !selectAll ? selected : undefined,
        asset: singleAsset && selected.length === 1 ? selected[0] : undefined,
        from: "Mobile Management",
      };
    },
    [selectedRows, assets, queries, selectAll],
  );

  const actions: Action[] = [
    {
      id: 1,
      enabled: useHasAnyFeatureEnabled(Feature.Bars),
      value: "Manage Bars",
      action: () =>
        navigate(`/mobile-management/bars/update${location.search}`, {
          state: getSelectedParams(),
        }),
    },
    {
      id: 2,
      enabled: useHasAnyFeatureEnabled(Feature.AutoSwitchPacStac),
      value: "Request PAC/STAC",
      action: () => {
        navigate(`/mobile-management/sim-switch${location.search}`, {
          state: getSelectedParams(),
        });
      },
    },
    {
      id: 3,
      enabled: useHasAnyFeatureEnabled(Feature.WorldwideDataRoaming),
      value: "Worldwide Data Roaming",
      action: () => {
        navigate(`/mobile-management/data-roaming${location.search}`, {
          state: getSelectedParams(),
        });
      },
    },
    {
      id: 4,
      enabled: useHasAnyFeatureEnabled(Feature.SimSwap),
      value: "Perform SIM Swap",
      action: () =>
        navigate(`/mobile-management/sim-swap${location.search}`, {
          state: getSelectedParams(),
        }),
    },
    {
      id: 5,
      enabled: useHasAnyFeatureEnabled(Feature.BillLimit),
      value: "Update Mobile Bill Limit",
      action: () => {
        navigate(`/mobile-management/bill-limit${location.search}`, {
          state: getSelectedParams(),
        });
      },
    },
    {
      id: 6,
      enabled: useHasAnyFeatureEnabled(Feature.SimLock),
      value: "Block Lost/Stolen SIM",
      action: () =>
        navigate(`/mobile-management/sim-lock${location.search}`, {
          state: getSelectedParams(true),
        }),
    },
    {
      id: 7,
      enabled: useHasAnyFeatureEnabled(Feature.SimUnlock),
      value: "Unlock SIM",
      action: () =>
        navigate(`/mobile-management/sim-unlock${location.search}`, {
          state: getSelectedParams(true),
        }),
    },
    {
      id: 8,
      enabled: useHasAnyFeatureEnabled(Feature.SimOrder),
      value: "Order New SIM",
      action: () => {
        navigate(`/mobile-management/sim-order${location.search}`, {
          state: getSelectedParams(true),
        });
      },
    },
  ];

  const actionError = (actionId?: number) => {
    const maxLimitMap: { [key: number]: any } = {
      1: undefined, // mobile bars
      2: MobileLimits.AutoSwitch,
      3: undefined, // data roaming
      4: MobileLimits.SimSwap,
      5: undefined, // bill limit
      6: MobileLimits.SimLock,
      7: MobileLimits.SimUnlock,
      8: MobileLimits.SimOrder,
    };
    const max = typeof actionId === "number" && maxLimitMap[actionId];
    if (selectedRows.length === 0) {
      return "Requires an asset to be selected";
    }
    if (max && (selectAll ? assetsResp?.totalElements || Infinity : selectedRows.length) > max) {
      return `Can only perform action on a maximum of ${max} assets`;
    }
    return "";
  };

  const handleTableChange = (action: string, state: any) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      case "rowSelectionChange":
        setSelectAll(false);
        // @hack - force to bottom of execution que
        setTimeout(() => {
          setSelectedRows(state.selectedRows.data?.map((r: any) => r?.index));
        });
        break;
    }
  };

  useEffect(
    function handleFilterChange() {
      setPage(0);
    },
    [queries],
  );

  const parsed = useMemo(
    () =>
      assets?.map((item: MobileAsset) => {
        return rowParser([item.cli, item.tag, capitalize(item.network), formatCostCentreCode(item.costCentre), item.accountCode]);
      }) || [],
    [assets],
  );

  useEffect(
    function handleSelectAll() {
      if (selectAll) {
        setSelectedRows(parsed.map((_, i) => i));
      } else {
        setSelectedRows([]);
      }
    },
    [selectAll, parsed],
  );

  useEffect(
    function keepActionsClosureUpdated() {
      setSelectedAction((prev) => actions.find((a) => a?.id === prev?.id));
    },
    // @important - actions object cannot be memoized as it contains hooks
    // eslint-disable-next-line
    [getSelectedParams],
  );

  const getActionFromValue = (value: string) => actions.find((a) => a?.value === value);

  return (
    <Main
      title="Mobile Management"
      data-cy="mobile-management-page"
      needSelectedAccount={true}
      feature={[
        Feature.AutoSwitchPacStac,
        Feature.Bars,
        Feature.BillLimit,
        Feature.SimLock,
        Feature.SimOrder,
        Feature.SimSwap,
        Feature.SimUnlock,
        Feature.WorldwideDataRoaming,
      ]}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      allowedUserTypes={[UserType.ACCOUNT, UserType.COST_CENTRE]}
      accessGrantedCb={() => setCanView(true)}
    >
      <MobileManagementTabBar selectedTab="mobile-management">
        <UITabPanel value="mobile-management" className={classes.ctr}>
          <UIGrid size={{ xs: 12, md: 12 }}>
            {userType === UserType.ACCOUNT && (
              <UIGrid>
                <FiltersCard
                  title="Filter"
                  fetchHook={useMobileManagementFilters}
                  hasReset={true}
                  hasDownload={false}
                  usage={MOBILE_NUMBERS_FILTERS}
                />
              </UIGrid>
            )}
            {isFetching ? (
              <div className={classes.loader}>
                <UILoader />
              </div>
            ) : (
              <>
                <UIAlert severity="info" className={classes.info}>
                  {`${
                    Boolean(selectedAction && actionError(selectedAction?.id))
                      ? actionError(selectedAction?.id)
                      : "Select asset(s) then select the action you want to perform."
                  }`}
                </UIAlert>
                <SelectAllHeader
                  value={selectAll}
                  onChange={() => setSelectAll(!selectAll)}
                  disabled={isFetching}
                  selectedRows={selectedRows || []}
                >
                  <SelectDropdown
                    key={actionKey}
                    ctrClass={classes.actionDropdown}
                    name="action-selection"
                    label="Select Actions"
                    onChange={(e: any) => {
                      const action = getActionFromValue(e.target.value);
                      setSelectedAction(action);
                    }}
                    data={actions
                      .filter((a) => Boolean(a.enabled))
                      .map((a) => {
                        const { action, ...option } = a;
                        return {
                          ...option,
                          label: <span className={actionError(option?.id) ? classes.actionDisabled : ""}>{option.value}</span>,
                        };
                      })}
                  />
                  <UIButton
                    variant="contained"
                    color="primary"
                    disabled={!selectedAction || Boolean(actionError(selectedAction?.id))}
                    onClick={() => {
                      if (selectedAction && !Boolean(actionError(selectedAction?.id))) {
                        selectedAction?.action();
                        setSelectedAction(undefined);
                        resetAction();
                      }
                    }}
                    data-cy="apply-action-btn"
                  >
                    Apply
                  </UIButton>
                </SelectAllHeader>
                <div data-cy="mobile-selection-table" className={classes.tableCtr}>
                  <UIDataTable
                    title=""
                    data={parsed}
                    columns={["CLI/Identifier", "Tag", "Network", "Cost Centre", "Account"]}
                    options={{
                      page,
                      rowsPerPage: rowCount,
                      count: assetsResp?.totalElements,
                      rowsPerPageOptions: rowCountOptions,
                      onTableChange: handleTableChange,
                      rowsSelected: selectedRows,
                      pagination: true,
                      download: false,
                      elevation: 1,
                      print: false,
                      responsive: "standard",
                      selectToolbarPlacement: "none",
                      filter: false,
                      viewColumns: false,
                      sort: false,
                      search: false,
                      serverSide: true,
                      rowHover: true,
                      selectableRowsHeader: false,
                      setTableProps: () => ({
                        size: "small",
                      }),
                      setRowProps: (row) => ({
                        "data-cy": `row-id-${row?.[0]}`,
                      }),
                      fixedHeader: false,
                      fixedSelectColumn: false,
                    }}
                  />
                </div>
              </>
            )}
          </UIGrid>
        </UITabPanel>
      </MobileManagementTabBar>
      <Outlet />
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    padding: 0,
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  info: {
    width: "fit-content",
    marginBottom: theme.spacing(3),
  },
  actionDropdown: {
    width: "fit-content",
    minWidth: 250,
  },
  actionDisabled: {
    color: theme.palette.action.disabled,
  },
  tableCtr: {
    marginTop: 0.5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    "& div:first-of-type": {
      marginTop: 0,
    },
    "& tr td:last-child": {
      width: "auto",
    },
    "& td": {
      width: "1%",
      whiteSpace: "nowrap",
    },
  },
}));

export default MobileManagement;
