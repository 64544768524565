import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InfiniteData } from "react-query/types/core/types";
import { AlertContact, GetUsageAlertsResponse, UsageAlert } from "../../../types";
import UsageAlertTableHeader from "./UsageAlertTableHeader";
import { useDeleteAlerts } from "../../../hooks";
import { rowParser } from "../../../../_app/components/Table/helpers";
import { RequestStatusLabel } from "../../../utils";
import { formatDate, humanize } from "../../../../_app/utils/format";
import { LoadMoreFooter } from "../../../../_app/components/Table/LoadMoreFooter";
import { UIButton, UILoader } from "../../../../_app/components";
import { createStylesheet } from "../../../../_app/utils/styles";
import UIDataTable, { UIDataTableColumnDef, UIDataTableOptions } from "../../../../_app/components/Table/UIDataTable";

interface UsageAlertTableProps {
  result: InfiniteData<GetUsageAlertsResponse> | undefined;
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  rowCount: number;
  onRowCountChange: (rowCount: number) => void;
}

const tableColumns: UIDataTableColumnDef[] = [
  {
    name: "ID",
    options: {
      display: "false",
    },
  },
  "Identifier",
  "Name",
  "Product",
  "Description",
  "Action",
  "Recipients",
  "Status",
  "Last Alert Date",
  {
    name: "",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <UIButton
            key={value.id}
            variant="text"
            color="primary"
            data-cy="edit"
            disabled={value.disabled}
            onClick={value.onEditClick}
          >
            Edit
          </UIButton>
        );
      },
    },
  },
];

const mapRecipients = (usageAlert: UsageAlert) => {
  const recipients = usageAlert.alertAction?.contacts?.map((contact: AlertContact) => `${contact.forename} ${contact.surname}`);
  // Add asset to recipients if alert is CLI and user should be notified by SMS
  if (usageAlert.level == "CLI" && usageAlert.alertAction?.notifyAssetsBySMS) {
    recipients?.unshift("Asset");
  }

  return recipients?.join(", ");
};

const UsageAlertTable: FC<UsageAlertTableProps> = ({
  result,
  isFetching,
  fetchNextPage,
  hasNextPage,
  rowCount,
  onRowCountChange,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState<UsageAlert[]>([]);

  const { mutate: deleteAlerts } = useDeleteAlerts();

  const alerts = useMemo(() => result?.pages?.map((page) => page?.list).flat() || [], [result]);

  const parsed = useMemo(
    () =>
      alerts?.map((item) => {
        return rowParser([
          item.id,
          item.cli || "All numbers on " + item.accountCode,
          item.name,
          humanize(item.product),
          item.description,
          item.alertAction?.bar?.value,
          mapRecipients(item),
          item.requestStatus ? RequestStatusLabel[item.requestStatus] : "",
          formatDate(item.latestBreachAlertedOn, "dd/MM/yyyy"),
          {
            id: item.id,
            disabled: item?.requestStatus?.includes("PENDING") ?? true,
            onEditClick: () => navigate(`/usage-alerts/${item.id}`, { state: { prevPath: location.pathname } }),
          },
        ]);
      }),
    [alerts, navigate, location.pathname],
  );

  useEffect(() => {
    setSelectedAlerts(alerts.filter((_, index) => selectedRows.includes(index)));
    // eslint-disable-next-line
  }, [selectedRows]);

  const handleDeleteAction = () => {
    deleteAlerts(selectedAlerts.map((alert) => alert.id || ""));
  };

  const handleBulkEditAction = () => {
    navigate("/usage-alerts/bulk-edit", { state: { selectedAlerts: selectedAlerts.map((alert) => alert.id) } });
  };

  const disableBulkEdit =
    selectedAlerts.length === 0 || selectedAlerts.some((alert) => alert.product !== "MOBILE" || alert.level === "ACCOUNT");

  const handleTableChange = useCallback(
    (action: string, state: any) => {
      switch (action) {
        case "changePage":
          setPage(state.page);
          break;
        case "changeRowsPerPage":
          onRowCountChange(state.rowsPerPage);
          break;
        case "rowSelectionChange":
          setSelectAll(false);
          setTimeout(() => {
            setSelectedRows(state.selectedRows.data?.map((r: any) => r?.index));
          });
          break;
      }
    },
    [onRowCountChange],
  );

  useEffect(() => {
    if (selectAll) {
      const indexes = alerts.reduce((result: number[], alert, index) => {
        if (!alert.requestStatus?.includes("PENDING")) {
          result.push(index);
        }
        return result;
      }, []);

      setSelectedRows(indexes);
    } else {
      setSelectedRows([]);
    }
    // eslint-disable-next-line
  }, [selectAll, parsed]);

  const tableOptions: UIDataTableOptions = {
    page,
    rowsPerPage: rowCount,
    rowsPerPageOptions: [25, 50, 100],
    onTableChange: handleTableChange,
    rowsSelected: selectedRows,
    pagination: false,
    customFooter: () => <LoadMoreFooter disabled={isFetching} hasMore={Boolean(hasNextPage)} onChangePage={fetchNextPage} />,
    serverSide: true,
    download: false,
    elevation: 1,
    print: false,
    responsive: "standard",
    selectToolbarPlacement: "none",
    filter: false,
    viewColumns: false,
    sort: false,
    search: false,
    rowHover: true,
    selectableRowsHeader: false,
    isRowSelectable: (dataIndex: number) => !alerts[dataIndex]?.requestStatus?.includes("PENDING"),
    setTableProps: () => ({ size: "small" }),
    setRowProps: (row) => ({
      "data-cy": `usage-alert-id-${row?.[0]}`,
    }),
  };

  return (
    <>
      <UsageAlertTableHeader
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectedRows={selectedRows}
        isFetching={isFetching}
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        handleDeleteAction={handleDeleteAction}
        handleBulkEditAction={handleBulkEditAction}
        disableBulkEdit={disableBulkEdit}
      />
      {isFetching ? (
        <div className={classes.loader}>
          <UILoader />
        </div>
      ) : (
        <div data-cy="usage-alerts-table" className={classes.tableCtr}>
          <UIDataTable title="" data={parsed} columns={tableColumns} options={tableOptions} />
        </div>
      )}
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  tableCtr: {
    marginTop: 0.5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    "& div:first-of-type": {
      marginTop: 0,
    },
  },
}));

export default UsageAlertTable;
